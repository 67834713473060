import React, { useEffect, useContext, useState } from 'react';
import UserContext from '../../contexts/context';
import PaymentService from '../../services/PaymentService';
import './BankModal.css';
import TokenService from '../../services/TokenService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import config from '../../config';

function BankModal({
  closeModal,
  payByAch,
  collectByAch,
  collectRetainersByAch,
  type,
  queue,
  invoices,
}) {
  const [banks, setBanks] = useState(['-']);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState();
  const [date, setDate] = useState(new Date());
  const [selectedBank, setSelectedBank] = useState({});
  const context = useContext(UserContext);
  console.log('type', type);
  // fetch banks from org
  useEffect(() => {
    PaymentService.getOrgBankAccounts(config.ORG_ID)
      .then((banks) => {
        console.log('banks: ', banks.banks);
        // update state
        const banksList = banks.banks.filter((bank) => {
          return bank.account_name.toLowerCase().includes('chase') === true;
        });
        setBanks(banksList);
      })
      .catch((e) => {
        console.log(e);
        setError(e.message);
      });
  }, []);

  useEffect(() => {
    let optionBuffer = banks.map((bank, i) => {
      return (
        <option key={i} value={bank.account_id}>
          {bank.account_name}
        </option>
      );
    });
    setOptions(optionBuffer);
    console.log(optionBuffer);
    setSelectedBank({ ...banks[0] });
  }, [banks]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // const nachaDate = year + month + day;
    if (type === 'bills') {
      // date.toLocaleDateString(dateOptions)
      payByAch(selectedBank, date, queue);
    } else if (type === 'invoices') {
      collectByAch(selectedBank, date, invoices);
    } else if (type === 'retainers') {
      collectRetainersByAch(selectedBank, date);
    }
  };

  const handleChange = (e) => {
    // console.log(date.toLocaleDateString(dateOptions));
    const bankFilter = banks.filter((bank) => {
      return bank.account_id == e.target.value;
    });
    const bank = bankFilter[0];
    setSelectedBank({ ...bank });
  };

  return (
    <div className='bank-modalBackground'>
      <form className='bank-modalContainer' onSubmit={(e) => handleSubmit(e)}>
        <div class='titleCloseBtn'>
          <button onClick={() => closeModal(false)}>X</button>
        </div>
        <div className='bankModalTitle'>
          <h2>Please select the bank for this transaction</h2>
        </div>
        <div className='bankModalBody'>
          {error ? (
            <p className='red'>{error}</p>
          ) : (
            <div>
              <select
                name='orgBank'
                id='orgBank'
                onChange={(e) => handleChange(e)}
              >
                {options}
              </select>
              <div style={{ marginTop: '10px', marginRight: '15px' }}>
                <DatePicker
                  selected={date}
                  onChange={(changeDate) => setDate(changeDate)}
                  minDate={new Date()}
                />
              </div>
            </div>
          )}
        </div>
        <div className='bankModalFooter'>
          <button
            className='btn remove-button'
            onClick={() => {
              closeModal(false);
            }}
          >
            Cancel
          </button>
          <input type='submit' className='btn pay-button' value='Confirm' />
        </div>
      </form>
    </div>
  );
}

export default BankModal;
