import {useEffect,useContext} from 'react'
import { Route, Switch } from 'react-router-dom';
import './App.css';
import NavBar from '../NavBar/NavBar'
import Context from '../../contexts/context'
// routing
import PrivateRoute from '../Utils/PrivateRoute';
import PublicOnlyRoute from '../Utils/PublicOnlyRoute'
import ListView from '../../routes/ListView/ListView'
import LoginView from '../../routes/LoginView/LoginView'
import EditView from '../../routes/EditView/EditView'
import SettingsView from '../../routes/SettingsView/SettingsView'
import AuthView from '../../routes/AuthView/AuthView'

function App() {
  const UserContext = useContext(Context)

  useEffect(()=>{
    console.log(UserContext.userId)
    UserContext.userId === null ? console.log("logged out") : console.log("logged in")
    
  },[UserContext.userId])
  return (
    <section className="App">
      <header className="App-header">
        <NavBar />
      </header>
      <main>
        <Switch>
          <PrivateRoute path="/" exact component={ListView} />
          <PublicOnlyRoute path="/login" exact component={LoginView} />
          <PrivateRoute path="/editPrintPage" component={EditView} />
          <PrivateRoute path="/settings" component={SettingsView} />
          <Route path="/auth" component={AuthView} />
          {/* http://localhost:3000/auth/737495014/21855685/1000.IHQ71LFIFQGM16PFQP77TAQ3SGOSHQ/a3726759a13a9dee17dcc7c6b50cb3b4410ba3e953 */}
          {/* <Route path="/auth/:user_id/:org_id/:client_id/:client_secret" component={AuthView} /> */}
        </Switch>
      </main>
    </section>
  );
}

export default App;
