import config from '../config';

const QueueService = {
  postBill(bill, paymentAmount, org) {
    console.log('adding to queue:', bill);
    return fetch(`${config.API_ENDPOINT}/queues/${bill}`, {
      method: 'PUT',
      body: JSON.stringify({ paymentAmount }),
      headers: {
        // Authorization: `Bearer ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization: org,
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  dequeueBill(bill, org) {
    // console.log('adding to queue:', bill);
    return fetch(`${config.API_ENDPOINT}/queues/dequeue/${bill}`, {
      method: 'PUT',
      // body: JSON.stringify({ paymentAmount }),
      headers: {
        // Authorization: `Bearer ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization: org,
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  // clearQueue(bill,org) {
  //   console.log("clear queue:",bill)
  //   return fetch(`${config.API_ENDPOINT}/queues/${bill}`, {
  //     method: 'PUT',
  //     // body: JSON.stringify({billId:bill}),
  //     headers: {
  //       // Authorization: `Bearer ${TokenService.getAuthToken()}`,
  //       'content-type': 'application/json',
  //       Organization:org
  //     },
  //   }).then((res) => {
  //     if (!res.ok) {
  //       return res.json().then((error) => {
  //         throw error;
  //       });
  //     }
  //     return res.json();
  //   });
  // },
  async getAllBills(org) {
    return fetch(`${config.API_ENDPOINT}/queues`, {
      method: 'GET',
      headers: {
        // Authorization: `Bearer ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization: org,
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  getBillById(id, org) {
    return fetch(`${config.API_ENDPOINT}/queues/${id}`, {
      method: 'GET',
      headers: {
        // Authorization: `Bearer ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization: org,
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  deleteBill(id, org) {
    return fetch(`${config.API_ENDPOINT}/queues/${id}`, {
      method: 'DELETE',
      body: JSON.stringify(id),
      headers: {
        // Authorization: `Bearer ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization: org,
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
};

export default QueueService;
