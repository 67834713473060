import React, { Component } from 'react'

const Context = React.createContext({
  currentUser: {},
  get userId() {
    return localStorage.getItem('user_id');
  },
  get currentOrg(){
    return localStorage.getItem('organization_id')
  },
  setUserId: () => {},
  setCurrentOrg:  (orgId) =>{
    return window.localStorage.setItem('organization_id',orgId)
    // this.setState({currentOrg:localStorage.setItem('organization_id',orgId)})
  },
  setCurrentUser: (user) => {
    console.log("updating user")
    this.setState({ currentUser: user })
  },
  loginError:"",
  checkNumber:1,
  setCheckNumber:() =>{}
})

export default Context;

export class Provider extends Component {
  state = {
    currentUser: {},
    currentOrg: localStorage.getItem('organization_id'),
    sideDrawerOpen: false,
    userId: localStorage.getItem('user_id'),
    loginError:"",
    checkNumber:1
  }

  setCurrentUser = async (user) => {
    console.log("updating user")
    this.setState({ currentUser: user })
  }
  setCurrentOrg = async (orgId) =>{
    this.setState({currentOrg:localStorage.setItem('organization_id',orgId)})
  }
  setUserId = (userId) => {
    this.setState({ userId: window.localStorage.setItem('user_id', userId) });
  };

  setLoginError = (error) =>{
    this.setState({loginError:error})
  }

  setCheckNumber = (number) =>{
    this.setState({checkNumber:number})
  }

  handleBackdropClose = () => {
    this.setState({ sideDrawerOpen: false });
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  render() {
    const values = {
      userId: this.state.userId,
      setUserId: this.setUserId,
      currentUser: this.state.currentUser,
      setCurrentUser: this.setCurrentUser,
      setCurrentOrg:this.setCurrentOrg,
      currentOrg:this.state.currentOrg,
      sideDrawerOpen: this.state.sideDrawerOpen,
      closeBackdrop: this.handleBackdropClose,
      toggleSideDrawer: this.drawerToggleClickHandler,
      loginError:this.state.loginError,
      setLoginError:this.setLoginError,
      checkNumber:this.state.checkNumber,
      setCheckNumber:this.setCheckNumber
      // getCurrentUser:this.getCurrentUser
    }
    return (
      <Context.Provider value={values}>
        {this.props.children}
      </Context.Provider>
    )
  }
}