import React, { useEffect, useState } from 'react';

export default function RetainerList(props) {
  const [sortAscending, setSortAscending] = useState(true);
  const [sortValue, setSortValue] = useState('');
  const [retainers, setRetainers] = useState([]);
  const [retainerList, setRetainerList] = useState();
  const [selectedRetainers, setSelection] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (retainerList !== props.retainers) {
      var batched = [];
      const retainerInvs = props.retainers
        .map((inv) => {
          inv.selected = false;
          return inv;
        })
        .filter((inv) => {
          if (inv.batched) {
            batched.push(inv);
          } else {
            return inv;
          }
        });

      setRetainerList([...batched, ...retainerInvs]);
    }
  }, [props.retainers]);

  useEffect(() => {
    if (selectAll === true) {
      retainerList.forEach((retainer) => {
        updateSelectedRetainers(retainer.id);
      });
    }
    setSelection([]);
  }, [selectAll]);

  useEffect(() => {
    sortRows(sortValue);
  }, [sortAscending]);

  const sortRows = (value) => {
    setSortValue(value);
    var sorted = retainerList;

    if (value === 'customer') {
      sorted = retainerList.sort((retainerA, retainerB) => {
        if (sortAscending) {
          var customerA = retainerA.customer.toUpperCase();
          var customerB = retainerB.customer.toUpperCase();
          return customerA < customerB ? -1 : customerA > customerB ? 1 : 0;
        } else {
          var customerA = retainerA.customer.toUpperCase();
          var customerB = retainerB.customer.toUpperCase();
          return customerA > customerB ? -1 : customerA < customerB ? 1 : 0;
        }
      });
    } else if (value === 'retainer_number') {
      sorted = retainerList.sort((retainerA, retainerB) => {
        if (sortAscending) {
          var invNumA = retainerA.retainer_number.toUpperCase();
          var invNumB = retainerB.retainer_number.toUpperCase();
          return invNumA < invNumB ? -1 : invNumA > invNumB ? 1 : 0;
        } else {
          var invNumA = retainerA.retainer_number.toUpperCase();
          var invNumB = retainerB.retainer_number.toUpperCase();
          return invNumA > invNumB ? -1 : invNumA < invNumB ? 1 : 0;
        }
      });
    } else if (value === 'date') {
      sorted = retainerList.sort((retainerA, retainerB) => {
        if (sortAscending) {
          var dateA = new Date(retainerA.date);
          var dateB = new Date(retainerB.date);
          return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
        } else {
          var dateA = new Date(retainerA.date);
          var dateB = new Date(retainerB.date);
          return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        }
      });
    } else if (value === 'status') {
      sorted = retainerList.sort((retainerA, retainerB) => {
        if (sortAscending) {
          var statusA = retainerA.status.toUpperCase();
          var statusB = retainerB.status.toUpperCase();
          return statusA < statusB ? -1 : statusA > statusB ? 1 : 0;
        } else {
          var statusA = retainerA.status.toUpperCase();
          var statusB = retainerB.status.toUpperCase();
          return statusA > statusB ? -1 : statusA < statusB ? 1 : 0;
        }
      });
    } else if (value === 'dueDate') {
      sorted = retainerList.sort((retainerA, retainerB) => {
        if (sortAscending) {
          var dateA = new Date(retainerA.due_date);
          var dateB = new Date(retainerB.due_date);
          return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
        } else {
          var dateA = new Date(retainerA.date);
          var dateB = new Date(retainerB.date);
          return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        }
      });
    } else if (value === 'amount') {
      sorted = retainerList.sort((retainerA, retainerB) => {
        if (sortAscending) {
          var amountA = retainerA.amount;
          var amountB = retainerB.amount;
          return amountA < amountB ? -1 : amountA > amountB ? 1 : 0;
        } else {
          var amountA = retainerA.amount;
          var amountB = retainerB.amount;
          return amountA > amountB ? -1 : amountA < amountB ? 1 : 0;
        }
      });
    }

    // place batched retainers at the front of the list, retain sort order
    var batches = [];
    if (retainerList !== undefined) {
      sorted = sorted.filter((inv) => {
        if (inv.batched) {
          batches.push(inv);
        } else {
          return inv;
        }
      });
      sorted = [...batches, ...sorted];
    }

    setRetainerList(sorted);

    if (retainerList !== undefined) {
      let updatedRetainers = retainerList.map((retainer) => {
        return (
          <tr key={retainer.id}>
            <td>
              {selectAll === true ? (
                <input
                  type='checkbox'
                  name={retainer.id}
                  value={retainer.selected}
                  id={retainer.id}
                  onChange={(e) => updateSelectedRetainers(e.target.name)}
                />
              ) : (
                <input
                  type='checkbox'
                  name={retainer.id}
                  value={retainer.selected}
                  id={retainer.id}
                  onChange={(e) => updateSelectedRetainers(e.target.name)}
                />
              )}
            </td>
            <td className='text-left'>
              {new Date(retainer.date).toLocaleDateString()}
            </td>
            <td className='text-left'>{retainer.retainer_number}</td>
            <td className='text-left'>
              <input type='checkbox' disabled value={!!retainer.batched} />
            </td>
            <td className='text-left'>{retainer.customer}</td>
            <td className='text-left'>{retainer.status}</td>
            <td className='text-left'>
              {new Date(retainer.due_date).toLocaleDateString()}
            </td>
            <td className='text-right'>${retainer.amount}</td>
            <td className='text-right'>
              $
              <input
                type='number'
                disabled='disabled'
                id={retainer.id}
                name={retainer.id + 'balance'}
                value={retainer.amount}
                onChange={(e) => updateBalance(retainer.id, e.target.value)}
              />
            </td>
          </tr>
        );
      });

      setRetainers(updatedRetainers);
    }
  };

  const updateSelectedRetainers = (id) => {
    // if selectAll is true, remove retainer from select state
    if (selectAll === true) {
      let i = selectedRetainers.indexOf(id);
      let updatedRetainers = retainerList.filter((retainer) => {
        return retainer.id !== id;
      });
      let selection = selectedRetainers.splice(i, 1);
      setSelection(selection);
      setRetainerList(updatedRetainers);
    } else {
      let selection = [];

      setRetainerList(
        retainerList.map((retainer) => {
          if (retainer.id === id) {
            retainer.selected = !retainer.selected;
          }
          if (retainer.selected === true) selection.push(retainer.id);
          return retainer;
        })
      );
      let updatedRetainers = retainerList.map((retainer) => {
        return (
          <tr key={retainer.id}>
            <td>
              {selectAll === true ? (
                <input
                  type='checkbox'
                  name={retainer.id}
                  value={retainer.selected}
                  id={retainer.id}
                  onChange={(e) => updateSelectedRetainers(e.target.name)}
                />
              ) : (
                <input
                  type='checkbox'
                  name={retainer.id}
                  value={retainer.selected}
                  id={retainer.id}
                  onChange={(e) => updateSelectedRetainers(e.target.name)}
                />
              )}
            </td>
            <td className='text-left'>
              {new Date(retainer.date).toLocaleDateString()}
            </td>
            <td className='text-left'>{retainer.retainer_number}</td>
            <td className='text-left'>
              <input type='checkbox' disabled value={!!retainer.batched} />
            </td>
            <td className='text-left'>{retainer.customer}</td>
            <td className='text-left'>{retainer.status}</td>
            <td className='text-left'>
              {new Date(retainer.due_date).toLocaleDateString()}
            </td>
            <td className='text-right'>${retainer.amount}</td>
            <td className='text-right'>
              $
              <input
                type='number'
                disabled='disabled'
                id={retainer.id}
                name={retainer.id + 'balance'}
                value={retainer.amount}
                onChange={(e) => updateBalance(retainer.id, e.target.value)}
              />
            </td>
          </tr>
        );
      });

      setRetainers(updatedRetainers);
      setSelection(selection);
    }
  };

  useEffect(() => {
    props.handleSelection(selectedRetainers);
  }, [selectedRetainers]);

  const updateBalance = (id, value) => {
    let filteredRetainers = retainerList.filter(
      (retainer) => retainer.status !== 'paid'
    );
    let updatedRetainers = filteredRetainers.map((retainer) => {
      if (retainer.id === id) {
        retainer.balance = value;
      }
      return (
        <tr key={retainer.id}>
          <td>
            {selectAll === true ? (
              <input
                type='checkbox'
                name={retainer.id}
                value={retainer.selected}
                id={retainer.id}
                onChange={(e) => updateSelectedRetainers(e.target.name)}
              />
            ) : (
              <input
                type='checkbox'
                name={retainer.id}
                value={retainer.selected}
                id={retainer.id}
                onChange={(e) => updateSelectedRetainers(e.target.name)}
              />
            )}
          </td>
          <td className='text-left'>
            {new Date(retainer.date).toLocaleDateString()}
          </td>
          <td className='text-left'>{retainer.retainer_number}</td>
          <td className='text-left'>
            <input type='checkbox' disabled checked={!!retainer.batched} />
          </td>
          <td className='text-left'>{retainer.customer}</td>
          <td className='text-left'>{retainer.status}</td>
          <td className='text-left'>
            {new Date(retainer.due_date).toLocaleDateString()}
          </td>
          <td className='text-right'>${retainer.amount}</td>
          <td className='text-right'>
            $
            <input
              type='number'
              disabled='disabled'
              id={retainer.id}
              name={retainer.id + 'balance'}
              value={retainer.balance}
              onChange={(e) => updateBalance(retainer.id, e.target.value)}
            />
          </td>
        </tr>
      );
    });
    setRetainers(updatedRetainers);
  };

  useEffect(() => {
    if (retainerList !== undefined) {
      let filteredRetainers = retainerList.filter(
        (retainer) => retainer.status !== 'paid'
      );
      setRetainers(
        filteredRetainers.map((retainer) => {
          return (
            <tr key={retainer.id}>
              <td>
                {selectAll === true ? (
                  <input
                    type='checkbox'
                    name={retainer.id}
                    value={retainer.selected}
                    id={retainer.id}
                    onChange={(e) => updateSelectedRetainers(e.target.name)}
                  />
                ) : (
                  <input
                    type='checkbox'
                    name={retainer.id}
                    value={retainer.selected}
                    id={retainer.id}
                    onChange={(e) => updateSelectedRetainers(e.target.name)}
                  />
                )}
              </td>
              <td className='text-left'>
                {new Date(retainer.date).toLocaleDateString()}
              </td>
              <td className='text-left'>{retainer.retainer_number}</td>
              <td className='text-left'>
                <input type='checkbox' disabled checked={!!retainer.batched} />
              </td>
              <td className='text-left'>{retainer.customer}</td>
              <td className='text-left'>{retainer.status}</td>
              <td className='text-left'>
                {new Date(retainer.date).toLocaleDateString()}
              </td>
              <td className='text-right'>${retainer.amount}</td>
              <td className='text-right'>
                $
                <input
                  type='number'
                  disabled='disabled'
                  id={retainer.id}
                  name={retainer.id + 'balance'}
                  value={retainer.amount}
                  onChange={(e) => updateBalance(retainer.id, e.target.value)}
                />
              </td>
            </tr>
          );
        })
      );
    }
  }, [retainerList]);

  return (
    <table className='listTable'>
      <thead>
        <tr>
          <th></th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('date');
              }}
            >
              <div className='text-left'>DATE</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'date' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('retainer_number');
              }}
            >
              <div className='text-left'>INVOICE #</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'retainer_number' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th className='text-left'>IN BATCH</th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('customer');
              }}
            >
              <div className='text-left'>CUSTOMER NAME</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'customer' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('status');
              }}
            >
              <div className='text-left'>STATUS</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'status' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('date');
              }}
            >
              <div className='text-left'>DATE</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'date' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th>
            <div
              className='column-name-wrapper justify-right'
              onClick={() => {
                sortRows('amount');
              }}
            >
              <div className='text-right'>BALANCE DUE</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'balance' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th className='text-right'>PAYMENT AMOUNT</th>
        </tr>
      </thead>
      <tbody>{retainers}</tbody>
    </table>
  );
}
