export default {
  API_ENDPOINT: 'https://checkbackend.fieldtech.io',
  ZOHO_REFRESH_ENDPOINT:
    'https://accounts.zoho.com/oauth/v2/auth/refresh?&response_type=token&scope=AaaServer.profile.Read,email&client_id=1000.UCBUTZ063UKTI9FA0F0580H7NEQMTY&redirect_uri=https://check.fieldtech.io/auth',
  TEST_BOOKS_ORG_ID: '21855685',
  TOKEN_KEY: 'user-authentication-token',
  ACCESS_KEY: 'user-authentication-token',
  USER_KEY: 'user-authentication-token',
  ZOHO_BOOKS_API: 'https://books.zoho.com/api/v3/bills',
  ORG_ID: '758147334',
};

// API_ENDPOINT: 'http://localhost:5000',
// API_ENDPOINT: 'https://checkbackend.fieldtech.io',
