import React, { useState, useEffect, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print';
import ComponentToPrint from './ComponentToPrint';
import ComponentToPrint2 from './ComponentToPrint2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QueueService from '../../services/QueueService'
import printer from '../../assets/printer.png'
import BillList from '../BillList/BillList';
import Context from '../../contexts/context'
// import userContext from '../../contexts/context'
// teting, delete after backend added:
// import Store from '../../dummy/store.json'

// import printer from '../../printer.png'
// four modal subcomponents:
// print confirmation
// add check stock to printer
// update checks after printing
// check stub regular paper prompt

const DynamicModal = (props) => {
  // get saved settings from db
  const context = useContext(Context)
  const [modalRoute, setModalRoute] = useState("Print Confirmation")
  const [modalComponent, setModalComponent] = useState()
  // const [startingNumber, setStartingNumber] = useState(Store.printed[Store.printed.length-1].checkNumber + 1)
  const [startingNumber, setStartingNumber] = useState(1)
  const [queuedBills, setQueuedBills] = useState()
  const [vendorGroups, setVendors] = useState({})
  const [printResponse, setPrintResponse] = useState()
  const [defaultSettings, setDefaultSettings] = useState({ "date": new Date(), "mode": "checks", "method": "deposits" })
  const [positionSettings, setDefaultPositionSettings] = useState({
    "checkLine1": {
      "top": 100,
      "right":0
    },
    "checkLine2": {
      "top": 105,
      "left":0
    },
    "checkLine3": {
      "top": 120,
      "left":0
    },
    "checkLine4": {
      "top": 135,
      "left":0
    }
  })
  const history = useHistory();

  const handleStartingNumber = (e) => {
    setStartingNumber(e.target.value)
    // AuthService.getUserByID
    // get last_check_number from user
  }

  const handleConfirm = () => {
    console.log("in handleConfirm")

    setModalRoute("Check Stock")
  }
  const handleStubs = () => {
    console.log("in handleStubs")
    // save check numbers to DB, save starting check number to settings
    setModalRoute("Check Stubs")
  }
  // close modal
  const handleCloseOut = () => {
    console.log("in handleConfirm")
    // clear queue
    // print stubs
    props.closeModal()
    history.push("/");
  }
  // printing logic
  const handleAfterPrint = React.useCallback(() => {
    const action = modalRoute !== "Check Stubs" ? setModalRoute("Update Checks") : handleCloseOut
    console.log("`onAfterPrint` called");
  }, [])
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });
  // const handlePrint = () => {
  //   // print check function -> print all checks in queue based on template settings and settings/updated settings from previous modal (state)
  //   // clear queue but hold on to printed checks (state again?) for next modal
  //   // Store.lastNumber = lastNumber
  //   // for each q in queuedBills{
  //   // }
  //   callPrint();
  // }

  const autofill = () => {
    // populate check numbers based on starting check number and any errors
    // for each check in queue, save check number by starting with state.startingNumber and incrementing every loop
    // look at print response
    let checkNum = context.currentUser.last_check_number + 1
    let numberedQueue = queuedBills.forEach(bill => {
      bill = {
        ...bill,
        check_number: checkNum
      }
      checkNum = checkNum + 1
    })
    setQueuedBills(numberedQueue)
    // context.setCheckNumber(checkNum)
    return
  }

  const handleDefaultChange = (e) => {
    const { name, value } = e.target;
    setDefaultSettings({ ...defaultSettings, [name]: value })
  }

  useEffect(() => {
    setModalRoute("Print Confirmation")
    // get default settings from DB
    // set Starting number to last number in DB + 1
    QueueService.getAllBills(context.currentUser.organizationId || context.currentOrg).then(bills => setQueuedBills(bills))
    // get position settings from db
    // let vendorTempObj = vendorGroups
    // queuedBills.forEach(bill => {
    //   vendorTempObj[bill.vendor] = [...bill]
    // })
    // setVendors(vendorTempObj)
    // testing:
    // setQueuedBills(Store.queue)
    setDefaultSettings({ "date": new Date(), "mode": "checks", "method": "deposits" })
  }, [])

  // useEffect(()=>{
  //   // restart the whole cycle for each vendor group
  //     const vendors = vendorGroups.keys
  //     vendors.forEach(vendor =>{
  //       const vg = vendorGroups[vendor]
  //   //     // total values for each check and pass to print function, might need to make a hook?
  //     })
  // },[vendorGroups])

  // useEffect(()=>{
  // const testLastNumber = Store.printed[Store.printed.length-1].checkNumber
  // console.log(testLastNumber)
  // setStartingNumber(testLastNumber + 1)
  // },[startingNumber])

  useEffect(() => {
    let subtitle;
    console.log(modalRoute)
    // setting default date
    Date.prototype.toDateInputValue = (function () {
      var local = new Date(this);
      local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
      return local.toJSON().slice(0, 10);
    });
    // console.log("in useEffect")
    switch (modalRoute) {
      case "Print Confirmation":
        console.log("in first case")
        setModalComponent(<>
          <div className="modalHead">
            <h2 ref={_subtitle => (subtitle = _subtitle)}>1. Print Confirmation</h2>
            <button className="closeButton" onClick={props.closeModal}> <FontAwesomeIcon icon='times' size="2x" /></button>
          </div>
          <div className="modalBody">
            {/* prepopulate values with user settings */}
            <form>
              <div className="input-group">
                <label>Payment Date</label>
                <input type="date" value={defaultSettings.date.toDateInputValue()} onChange={e => handleDefaultChange(e)} />
              </div>
              <div className="input-group">
                <label>Payment Mode</label>
                <input type="text" name="mode" id="mode" value={defaultSettings.mode} onChange={e => handleDefaultChange(e)} />
              </div>
              <div className="input-group">
                <label>Paid Through</label>
                {/* <input type="text" /> */}
                <select name="paymentMethod" id="paymentMethod" value={defaultSettings.method} onChange={e => handleDefaultChange(e)}>
                  {/* will come from db, set default to setting choice */}
                  <option value="deposits">Deposits</option>
                </select>
              </div>
              <div className="input-group">
                <label>Starting Check Number</label>
                <input type="number" name="startingNum" id="startingNum" value={startingNumber} onChange={e => handleStartingNumber(e)} />
              </div>
              {/* <input className="btn pay-button modalButton" type="submit" value="Print" onSubmit={handleConfirm} /> */}
            </form>
            <button className="btn pay-button modalButton" onClick={handleConfirm}>Print</button>
          </div>
        </>)
        break
      case "Check Stock":
        console.log("QUEUE: ",queuedBills)
        setModalComponent(<>
          <div className="modalHead">
            <h2 ref={_subtitle => (subtitle = _subtitle)}>2. Add Check Stock to Printer</h2>
            <button className="closeButton" onClick={props.closeModal}> <FontAwesomeIcon icon='times' size="2x" /></button>
          </div>
          <div className="modalBody">
            Click OK to print.
            <img className="printerIcon" src={printer} alt="Printer Icon" />
            <button className="btn add-button modalButton" onClick={handlePrint}>OK</button>
          </div>
          <div style={{ display: "none" }}>
            {/* probably won't need bill, bills, or company */}
            {queuedBills.bills.map(bill => {
              // return <ComponentToPrint ref={componentRef} bill={bill} bills={BillList.bills} company={Store.company} positionSettings={Store.positionSettings} />
              return <ComponentToPrint ref={componentRef} key={bill.id} bill={bill} company={context.currentUser.company || ""} bills={queuedBills.bills} positionSettings={positionSettings}/>
            })}
          </div>
        </>)
        break
      case "Update Checks":
        let printedChecks = queuedBills.map(bill => {
          return (
            <div className="updateBillRow">
              <label className="updateBillLabel" htmlFor={bill.id}>Check for {bill.balance} to {bill.vendor} <span className="updateBillSpan">{bill.method || 'Bank Account: ABC'}</span></label>
              <input name={bill.id} />
            </div>
          )
        })
        setModalComponent(<>
          <div className="modalHead">
            <h2 ref={_subtitle => (subtitle = _subtitle)}>3. Update Status of Printed Checks</h2>
            <button className="closeButton" onClick={props.closeModal}> <FontAwesomeIcon icon='times' size="2x" /></button>
          </div>
          <div className="modalBody">
            {/* for each check in queue */}
            <div className="updateCheckHeader">
              <p>Select the checks that were printed successfully</p>
              <span onClick={autofill} className="blue-link">Autofill check numbers</span>
            </div>
            <form>
              {printedChecks}
              {/* for each bill that was printed from queue */}
              {/* <input className="btn pay-button modalButton" type="submit" value="OK" onSubmit={handleStubs} /> */}
            </form>
            <div className="footnote">
              <span className="red">Note: </span>
              Succesfully printed checks will be marked as
              <span className="bolder"> Uncleared</span>
            </div>
            <button className="btn pay-button modalButton" onClick={handleStubs}>Ok</button>
          </div>
        </>)
        break
      case "Check Stubs":
        setModalComponent(<>
          <div className="modalHead">
            <h2 ref={_subtitle => (subtitle = _subtitle)}>4. Your checks have printed.</h2>
            <button className="closeButton" onClick={props.closeModal}> <FontAwesomeIcon icon='times' size="2x" /></button>
          </div>
          <div className="modalBody">
            <img className="printerIcon" src={printer} alt="Printer Icon" />
            <p>Please add regular paper to your printer for the check stubs. Hit OK when paper has been added. All Stubs will be printed on report if greater than ten.</p>
            {/* update useRef to second ComponentToPrint page */}
            <button className="btn add-button modalButton" onClick={handlePrint} >OK</button>
          </div>
          <div style={{ display: "none" }}>
            {/* probably won't need bill, bills, or company */}
            return <ComponentToPrint2 ref={componentRef} />
          </div>
        </>)
        break
      default:
        setModalComponent(<>
          <div className="modalHead">
            <h2 ref={_subtitle => (subtitle = _subtitle)}>Print Confirmation</h2>
            <button className="closeButton" onClick={props.closeModal}> <FontAwesomeIcon icon='times' size="2x" /></button>
          </div>
          <div className="modalBody">
            {/* prepopulate values with user settings */}
            <form>
              <div>
                <label>Payment Date</label>
                <input type="date" />
              </div>
              <div>
                <label>Payment Mode</label>
                <input type="text" />
              </div>
              <div>
                <label>Paid Through</label>
                {/* <input type="text" /> */}
                <select name="paymentMethod" id="paymentMethod">
                  {/* will come from db, set default to setting choice */}
                  <option value="deposits">Deposits</option>
                </select>
              </div>
              <div>
                <label>Starting Check Number</label>
                <input type="number" />
              </div>
              {/* <input className="btn pay-button" type="submit" value="Print" onSubmit={handleConfirm} /> */}
            </form>
            <button className="btn pay-button modalButton" onClick={handleConfirm}>Print</button>
          </div>
        </>)
        break
    }
  }, [modalRoute, props])
  // console.log(modalComponent)
  return (
    <>
      {modalComponent}
    </>
  )
}

export default DynamicModal;