import React, { useEffect, useContext } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import Context from '../../contexts/context';
import TokenService from '../../services/TokenService';
import AuthService from '../../services/AuthService';
import BillService from '../../services/BillService';
import InvoiceService from '../../services/InvoiceService';

export default function AuthView() {
  let history = useHistory();
  const { hash } = useLocation();
  // const dispatch = useAuthDispatch();
  const UserContext = useContext(Context);
  const urlHashObj = queryString.parse(hash);
  // test
  useEffect(() => {
    console.log('urlHashObj:', urlHashObj);
    AuthService.loginCallBack(urlHashObj)
      .then((resp) => {
        console.log('1: ', resp);
        UserContext.setLoginError('');
        // TokenService.saveAuthToken(resp.auth_token)
        // TokenService.saveAccessToken(urlHashObj.access_token)
        // TokenService.saveUserToken(urlHashObj.id_token)
        // UserContext.setUserId(urlHashObj.id_token)
        // console.log(resp.user)
        // save current user email
        // I need to interact with the backend, I need to log in to the backend, fetch the org id and fetch the bills
        AuthService.postLogin(resp.user.email, resp.auth_token)
          .then(async (res) => {
            console.log('Login Res: ', res.user);
            UserContext.setCurrentOrg(res.user.organizationId);
            UserContext.setUserId(res.user.id);
            TokenService.saveAuthToken(resp.auth_token);
            console.log(TokenService.getAuthToken());
            UserContext.setCurrentUser(res.user);
            try {
              history.push('/');
            } catch (error) {
              console.log(error);
              UserContext.setLoginError(
                'An error occurred while logging in. This could be the result of a zoho authorization error'
              );
              history.push('/login');
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            UserContext.setLoginError(
              'An error occurred while logging in. This could be the result of a zoho authorization error'
            );
            history.push('/login');
            return;
          });
      })
      .catch((error) => {
        console.log(error);
        UserContext.setLoginError(
          'An error occurred while logging in. This could be the result of a zoho authorization error'
        );
        history.push('/login');
      });
  }, []);

  // console.log(urlHashObj)
  return (
    <div className='lds-ring absoluteCenter'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );

  // const [formState,setFormState] =useState({email:'',password:''});

  // useEffect(() => {
  //   const {user_id,orgId,client_id,client_secret} = useParams;
  //   // check if user exists in
  //   return () => {
  //     // setForm
  //   }
  // }, [])
  // const handleChange = (e) =>{

  // }

  // const handleSubmit = (e) =>{

  // }

  // return (
  //   <section className="settingCard">
  //   <form>
  //     <div className="input-group">
  //       <label>Email</label>
  //       <input type="email" name="email" value={formState.email} onChange={e => handleChange(e.target)} />
  //     </div>
  //     {/* <div className="input-group">
  //       <label>Password</label>
  //       <input type="password" name="password" value={formState.password} onChange={e => handleChange(e.target)}/>
  //     </div> */}
  //     {/* <input className="btn pay-button modalButton" type="submit" value="Print" onSubmit={handleConfirm} /> */}
  //     <button className="btn pay-button modalButton" type="submit" onClick={handleSubmit}>Log In</button>
  //   </form>
  // </section>
  // )
}
