import config from '../config';

const TokenService = {
  saveAuthToken(token) {
    // console.log("save token")
    window.localStorage.setItem(config.TOKEN_KEY, token);
  },
  saveAccessToken(token) {
    // console.log("save token")
    window.localStorage.setItem(config.ACCESS_KEY, token);
  },
  saveUserToken(token) {
    // console.log("save token")
    window.localStorage.setItem(config.USER_KEY, token);
  },
  getAuthToken() {
    // console.log("get token")
    return window.localStorage.getItem(config.TOKEN_KEY);
  },
  getUserToken() {
    // console.log("get token")
    return window.localStorage.getItem(config.USER_KEY);
  },
  clearAuthToken() {
    // console.log("clear auth")
    window.localStorage.clear();
  },
  hasAuthToken() {
    // console.log("has auth?")
    return !!TokenService.getAuthToken();
  },
  makeBasicAuthToken(userName, password) {
    return window.btoa(`${userName}:${password}`);
  },
};

export default TokenService;