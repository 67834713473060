import React, { useState, useEffect,useContext } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TokenService from '../../services/TokenService';
import Context from '../../contexts/context'

export default function SettingsView() {
  const userContext = useContext(Context)
  // i.e updated successfully, or error
  // const [dbResp,setDBResp] = useState('')
  const [paymentMethods,setPaymentMethods] = useState(['deposits','Alt Payment method'])
  const [defaultSettings,setDefaultSettings] = useState({mode:'Checks',method:'deposits'})
  // const [defaultPaymentmethod,setDefaultPaymentMethods] = useState('deposits')
  const handleConfirm = () =>{
    // update DB Settings, display update message
    // save defaultPaymentmethod to DB
    // save default mode to DB
    console.log(defaultSettings)
  }

  const handleLogout = () =>{
    TokenService.clearAuthToken();
    userContext.setCurrentUser({});
    userContext.setUserId(null)
  }

  const handleChange = (e) =>{
    // console.log(e)
    const {name,value} = e
    setDefaultSettings({
      ...defaultSettings,
      [name]:value
    })
  }
  
  useEffect(()=>{
    console.log(defaultSettings)
  },[defaultSettings])
  
  useEffect(()=>{
    // get payment methods from DB/Books
    // setPaymentmethods()
    // get defaults from DB
  },[])
  
  const options = paymentMethods.map(method => <option value={method} style={{textTransform:'capitalize'}}>{method}</option>)
  return (
    <section className="settingCard">
      <form>
        {/* <div className="input-group">
          <label>Payment Date</label>
          <input type="date" />
        </div> */}
        <div className="input-group">
          <label>Payment method</label>
          <input type="text" name="mode" value={defaultSettings.mode} onChange={e => handleChange(e.target)}/>
        </div>
        <div className="input-group">
          <label>Paid Through</label>
          <select name="method" id="method" value={defaultSettings.method} onChange={e => handleChange(e.target)}>
            {options}
          </select>
        </div>
        {/* <input className="btn pay-button modalButton" type="submit" value="Print" onSubmit={handleConfirm} /> */}
        <button className="btn pay-button modalButton" type="submit" onClick={handleConfirm}>Confirm</button>
      </form>
      <div className="button-bar button-bar-padding flex-column">
        <Link to="/editPrintPage" className="btn add-button removeTextDecoration"><FontAwesomeIcon icon='cog' color="white" /> Edit Check Template</Link>
        <Link to="/login" onClick={() => handleLogout()} className="btn button-alt removeTextDecoration">Log Out</Link>
        <Link to="/" className="btn button-alt removeTextDecoration">Go back</Link>
      </div>
    </section>
  )
}
