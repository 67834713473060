const FormatFunctions = {
    formatDollars(amount) {
        if (typeof amount == 'number') {
            return "$" + (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        } else if (typeof amount == 'string') {
            amount = parseFloat(amount);
            return "$" + (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
    }
}

export default FormatFunctions;