import config from '../config';
import TokenService from '../services/TokenService';

const AuthService = {
  // check if user exists/get user by email
  checkEmail(email) {
    return fetch(`${config.API_ENDPOINT}/auth/validate`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(email),
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  async loginCallBack(payload) {
    console.log('loginCallBack Payload: ', payload);
    const { access_token, id_token } = payload;
    // const idTokenSplit = id_token[0].split('.');
    // testing
    const idTokenSplit = id_token.split('.');
    console.log(idTokenSplit);
    const emailStr = decodeURIComponent(escape(window.atob(idTokenSplit[1])));
    const emailObj = JSON.parse(emailStr);
    const data = {
      auth_token: access_token,
      user: {
        id: 1,
        email: emailObj['email'],
      },
    };
    console.log('LCB DATA:', data);
    // localStorage.setItem('currentUser', JSON.stringify(data.user))
    return data;
  },
  // send access token to backend to get refresh token
  // decrypt user data from id_token in backend
  // access_token: "1000.bf664111c78b0046817cb4752d898a0e.dc83c8c2bb324d2488d7ecce19f5cf52"
  // api_domain: "https://www.zohoapis.com"
  // expires_in: "3600"
  // granted_for_session: "true"
  // id_token: "eyJ0eXAiOiJKV1QiLCJrZXlfaWQiOiJucjBkODVodjJwNm1zZXd1eXk0bnNoczNoc2xoaGUxN2IwZmUzYmU0MiIsImFsZyI6IlJTMjU2Iiwia2lkIjoibnIwZDg1aHYycDZtc2V3dXl5NG5zaHMzaHNsaGhlMTdiMGZlM2JlNDIifQ.eyJhdF9oYXNoIjoiUG5jT0w0QlJzcFFaZ1ZUZVJIN3BCdyIsInN1YiI6IjcxODYyMzU0NC45OTA0NzcwMTkiLCJhdWQiOiIxMDAwLlVDQlVUWjA2M1VLVEk5RkEwRjA1ODBIN05FUU1UWSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhenAiOiIxMDAwLlVDQlVUWjA2M1VLVEk5RkEwRjA1ODBIN05FUU1UWSIsImlzcyI6Imh0dHBzOi8vYWNjb3VudHMuem9oby5jb20iLCJleHAiOjE2Mjg1NTY0NjMsImlhdCI6MTYyODU1Mjg2MywiZW1haWwiOiJicmVuZGVuZEBhZ2lsZWJ0cy5jb20ifQ.E1GdfRjVEXr_2fjC7kEzdENAKZv8gekJl8eNSvUuDe3qCUk-TG7hM66Urhd1KIIVtKpwlIF_EbTN-OCzpHWTxTiE1k8Fh7kCLpNjmZv-09OU1hIhUOb0LCwCoOWmU0K2rYeq32_-UmyLGso1AwCJYrfB93YbfHfx9vrtJD-obRHdQrVxxbul5dRtKptnmkwp8iGCJYuxKQrhSLptDTfM-_Qsv52x4fDrC4OXPKRL0wKLJ8jk0FAR3eFu7yNtgl8xRienaiNPK5ach4hhDppT_AtPAcSm9KCq59XnBPFK3lRfbTRnnPDJqzZ9-IECTzsMkM3hpww19L2xIoz-nPXaXg"
  // location: "us"
  refreshToken(refreshObj) {
    return fetch(
      `${config.ZOHO_REFRESH_URL}&client_id=${refreshObj.client_id}`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(refreshObj),
      }
    ).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  // post logIn
  postLogin(email, auth) {
    // need to pass access token urlHash, and auth token & email from resp...
    console.log('Post Login Access Key: ', auth);
    return fetch(`${config.API_ENDPOINT}/token/${email}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        // 'Access-Control-Allow-Headers': 'Origin, Content-Type, Authorization',
        // 'Access-Control-Allow-Methods': 'GET, POST',
        'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Credentials': 'true',
        Authorization: 'Zoho-oauthtoken ' + auth,
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  getUserByID(id) {
    console.log('as 78');
    return fetch(`${config.API_ENDPOINT}/users/${id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
};

export default AuthService;
