import React, { useEffect, useState, useContext } from 'react';

export default function BillList(props) {
  const [bills, setBills] = useState([]);
  const [sortAscending, setSortAscending] = useState(true);
  const [sortValue, setSortValue] = useState('');
  const [billList, setBillList] = useState([]);
  const [selectedBills, setSelection] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (billList !== props.bills) {
      setBillList(props.bills);
    }
  }, [props.bills]);

  const handleMassSelect = (e) => {
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectAll === true) {
      billList.forEach((bill) => {
        updateSelectedBills(bill.id);
      });
    }
    setSelection([]);
  }, [selectAll]);

  useEffect(() => {
    sortRows(sortValue);
  }, [sortAscending]);

  const sortRows = (value) => {
    setSortValue(value);
    var sorted = billList;
    if (value === 'vendor') {
      sorted = billList.sort((billA, billB) => {
        if (sortAscending) {
          var vendorA = billA.vendor.toUpperCase();
          var vendorB = billB.vendor.toUpperCase();
          return vendorA < vendorB ? -1 : vendorA > vendorB ? 1 : 0;
        } else {
          var vendorA = billA.vendor.toUpperCase();
          var vendorB = billB.vendor.toUpperCase();
          return vendorA > vendorB ? -1 : vendorA < vendorB ? 1 : 0;
        }
      });
    } else if (value === 'bill_number') {
      sorted = billList.sort((billA, billB) => {
        if (sortAscending) {
          var billNumberA = billA.bill_number.toUpperCase();
          var billNumberB = billB.bill_number.toUpperCase();
          return billNumberA < billNumberB
            ? -1
            : billNumberA > billNumberB
            ? 1
            : 0;
        } else {
          var billNumberA = billA.bill_number.toUpperCase();
          var billNumberB = billB.bill_number.toUpperCase();
          return billNumberA > billNumberB
            ? -1
            : billNumberA < billNumberB
            ? 1
            : 0;
        }
      });
    } else if (value === 'status') {
      sorted = billList.sort((billA, billB) => {
        if (sortAscending) {
          var statusA = billA.status.toUpperCase();
          var statusB = billB.status.toUpperCase();
          return statusA < statusB ? -1 : statusA > statusB ? 1 : 0;
        } else {
          var statusA = billA.status.toUpperCase();
          var statusB = billB.status.toUpperCase();
          return statusA > statusB ? -1 : statusA < statusB ? 1 : 0;
        }
      });
    } else if (value === 'reference_number') {
      sorted = billList.sort((billA, billB) => {
        if (sortAscending) {
          var refNumA = billA.reference_number.toUpperCase();
          var refNumB = billB.reference_number.toUpperCase();
          return refNumA < refNumB ? -1 : refNumA > refNumB ? 1 : 0;
        } else {
          var refNumA = billA.reference_number.toUpperCase();
          var refNumB = billB.reference_number.toUpperCase();
          return refNumA > refNumB ? -1 : refNumA < refNumB ? 1 : 0;
        }
      });
    } else if (value === 'dueDate') {
      sorted = billList.sort((billA, billB) => {
        if (sortAscending) {
          var dueDateA = new Date(billA.dueDate);
          var dueDateB = new Date(billB.dueDate);
          return dueDateA < dueDateB ? -1 : dueDateA > dueDateB ? 1 : 0;
        } else {
          var dueDateA = new Date(billA.dueDate);
          var dueDateB = new Date(billB.dueDate);
          return dueDateA > dueDateB ? -1 : dueDateA < dueDateB ? 1 : 0;
        }
      });
    } else if (value === 'balance') {
      sorted = billList.sort((billA, billB) => {
        if (sortAscending) {
          var balanceA = billA.balance;
          var balanceB = billB.balance;
          return balanceA < balanceB ? -1 : balanceA > balanceB ? 1 : 0;
        } else {
          var balanceA = billA.balance;
          var balanceB = billB.balance;
          return balanceA > balanceB ? -1 : balanceA < balanceB ? 1 : 0;
        }
      });
    } else if (value === 'date') {
      sorted = billList.sort((billA, billB) => {
        if (sortAscending) {
          var dateA = new Date(billA.date);
          var dateB = new Date(billB.date);
          return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
        } else {
          var dateA = new Date(billA.date);
          var dateB = new Date(billB.date);
          return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        }
      });
    }
    setBillList(sorted);

    if (billList !== undefined) {
      let updatedBills = billList.map((bill) => {
        return (
          <tr key={bill.id}>
            <td className="checkbox-wrapper">
              {selectAll === true ? (
                <input
                  type='checkbox'
                  name={bill.id}
                  className={bill.selected ? "checked" : ""}
                  value={bill.selected}
                  id={bill.id}
                  onChange={(e) => updateSelectedBills(e.target.name)}
                />
              ) : (
                <input
                  type='checkbox'
                  name={bill.id}
                  className={bill.selected ? "checked" : ""}
                  value={bill.selected}
                  id={bill.id}
                  onChange={(e) => updateSelectedBills(e.target.name)}
                />
              )}
            </td>
            <td className='text-left'>
              {new Date(bill.date).toLocaleDateString()}
            </td>
            <td className='text-left'>{bill.bill_number}</td>
            <td className='text-left'>{bill.reference_number}</td>
            <td className='text-left'>{bill.vendor}</td>
            <td className='text-left'>{bill.status}</td>
            <td className='text-left'>
              {new Date(bill.dueDate).toLocaleDateString()}
            </td>
            <td className='text-right'>${bill.balance}</td>
            <td className='text-right'>
              $
              <input
                type='number'
                size='20'
                id={bill.id}
                name={bill.id + 'balance'}
                value={bill.paymentAmount}
                onChange={(e) => updateBalance(bill.id, e.target.value)}
              />
            </td>
          </tr>
        );
      });
      setBills(updatedBills);
    }
  };

  const updateSelectedBills = (id) => {
    // console.log(id)
    // if selectAll is true, remove bill from select state
    if (selectAll === true) {
      let i = selectedBills.indexOf(id);
      // console.log(billList)
      let updatedBills = billList.filter((bill) => {
        return bill.id !== id;
      });
      // if (i >= 0) {
      // billList.splice(i, 1);
      let selection = selectedBills.splice(i, 1);
      setSelection(selection);
      setBillList(updatedBills);
      // }
    } else {
      let selection = [];
      // let selection = selectedBills
      setBillList(
        billList.map((bill) => {
          if (bill.id === Number(id)) {
            // console.log(bill.id)
            bill.selected = !bill.selected;
          }
          if (bill.selected === true) selection.push(bill.id);
          // console.log(selection)
          return bill;
        })
      );
      let updatedBills = billList.map((bill) => {
        return (
          <tr key={bill.id}>
            <td className="checkbox-wrapper">
              {selectAll === true ? (
                <input
                  type='checkbox'
                  name={bill.id}
                  className={bill.selected ? "checked" : ""}
                  value={bill.selected}
                  id={bill.id}
                  onChange={(e) => updateSelectedBills(e.target.name)}
                />
              ) : (
                <input
                  type='checkbox'
                  name={bill.id}
                  className={bill.selected ? "checked" : ""}
                  value={bill.selected}
                  id={bill.id}
                  onChange={(e) => updateSelectedBills(e.target.name)}
                />
              )}
            </td>
            <td className='text-left'>
              {new Date(bill.date).toLocaleDateString()}
            </td>
            <td className='text-left'>{bill.bill_number}</td>
            <td className='text-left'>{bill.reference_number}</td>
            <td className='text-left'>{bill.vendor}</td>
            <td className='text-left'>{bill.status}</td>
            <td className='text-left'>
              {new Date(bill.dueDate).toLocaleDateString()}
            </td>
            <td className='text-right'>${bill.balance}</td>
            <td className='text-right'>
              $
              <input
                type='number'
                size='20'
                id={bill.id}
                name={bill.id + 'balance'}
                value={bill.paymentAmount}
                onChange={(e) => updateBalance(bill.id, e.target.value)}
              />
            </td>
          </tr>
        );
      });
      // console.log(updatedBills)
      setBills(updatedBills);
      setSelection(selection);
    }
  };

  useEffect(() => {
    // console.log(selectedBills)
    props.handleSelection(selectedBills);
  }, [selectedBills]);

  const updateBalance = (id, value) => {
    // console.log(billList)
    // console.log(id, value)
    // const updatedBill = bills.find(bill => bill.id === id)
    let updatedBills = billList.map((bill) => {
      if (bill.id == id) {
        if (Number(value) >= Number(bill.balance)) {
          bill.paymentAmount = bill.balance;
        } else {
          bill.paymentAmount = value;
        }
      }
      return (
        <tr key={bill.id}>
          <td className="checkbox-wrapper">
            {selectAll === true ? (
              <input
                type='checkbox'
                name={bill.id}
                className={bill.selected ? "checked" : ""}
                value={bill.selected}
                id={bill.id}
                onChange={(e) => updateSelectedBills(e.target.name)}
              />
            ) : (
              <input
                type='checkbox'
                name={bill.id}
                className={bill.selected ? "checked" : ""}
                value={bill.selected}
                id={bill.id}
                onChange={(e) => updateSelectedBills(e.target.name)}
              />
            )}
          </td>
          <td className='text-left'>
            {new Date(bill.date).toLocaleDateString()}
          </td>
          <td className='text-left'>{bill.bill_number}</td>
          <td className='text-left'>{bill.reference_number}</td>
          <td className='text-left'>{bill.vendor}</td>
          <td className='text-left'>{bill.status}</td>
          <td className='text-left'>
            {new Date(bill.dueDate).toLocaleDateString()}
          </td>
          <td className='text-right'>${bill.balance}</td>
          <td className='text-right'>
            $
            <input
              type='number'
              size='20'
              id={bill.id}
              name={bill.id + 'balance'}
              value={bill.paymentAmount}
              onChange={(e) => updateBalance(bill.id, e.target.value)}
            />
          </td>
        </tr>
      );
    });

    setBills(updatedBills);

  };

  useEffect(() => {
    // console.log(billList)
    if (typeof billList[0] !== undefined) {
      setBills(
        billList.map((bill) => {
          return (
            <tr key={bill.id}>
              <td className="checkbox-wrapper">
                {selectAll === true ? (
                  <input
                    type='checkbox'
                    name={bill.id}
                    className={bill.selected ? "checked" : ""}
                    value={bill.selected}
                    id={bill.id}
                    onChange={(e) => updateSelectedBills(e.target.name)}
                  />
                ) : (
                  <input
                    type='checkbox'
                    name={bill.id}
                    className={bill.selected ? "checked" : ""}
                    value={bill.selected}
                    id={bill.id}
                    onChange={(e) => updateSelectedBills(e.target.name)}
                  />
                )}
              </td>
              <td className='text-left'>
                {new Date(bill.date).toLocaleDateString()}
              </td>
              <td className='text-left'>{bill.bill_number}</td>
              <td className='text-left'>{bill.reference_number}</td>
              <td className='text-left'>{bill.vendor}</td>
              <td className='text-left'>{bill.status}</td>
              <td className='text-left'>
                {new Date(bill.dueDate).toLocaleDateString()}
              </td>
              <td className='text-right'>${bill.balance}</td>
              <td className='text-right'>
                $
                <input
                  type='number'
                  size='20'
                  id={bill.id}
                  name={bill.id + 'balance'}
                  value={bill.paymentAmount}
                  onChange={(e) => updateBalance(bill.id, e.target.value)}
                />
              </td>
            </tr>
          );
        })
      );
    }
  }, [billList]);
  // $
  return (
    <table className='listTable'>
      <thead>
        <tr>
          <th></th>
          {/* <th><input type="checkbox" name="all" id="all" onChange={e => handleMassSelect(e)} /></th> */}

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('date');
              }}
            >
              <div className='text-left'> DATE </div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'date' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('bill_number');
              }}
            >
              <div className='text-left'> BILL #</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'bill_number' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('reference_number');
              }}
            >
              <div className='text-left'>REFERENCE NUMBER</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'reference_number' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('vendor');
              }}
            >
              <div className='text-left'>VENDOR NAME</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'vendor' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('status');
              }}
            >
              <div className='text-left'>STATUS</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'status' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('dueDate');
              }}
            >
              <div className='text-left'>DUE DATE</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'dueDate' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>
          <th>
            <div
              className='text-right'
              onClick={() => {
                sortRows('balance');
              }}
            >
              BALANCE DUE
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'balance' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>
          <th>
            <div className='column-name-wrapper justify-right'>
              <div className='text-right'>PAYMENT AMOUNT</div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>{bills}</tbody>
    </table>
  );
}
