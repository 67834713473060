import config from '../config';
import TokenService from './TokenService';

const BillService = {
  // get bills from DB per client
  getBills(org) {
    return fetch(`${config.API_ENDPOINT}/bills`, {
      method: 'GET',
      // body: JSON.stringify(bills),
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization: org,
      },
    }).then(async (res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      const billList = await res.json();
      console.log(billList);
      const bills = billList.bills.map((bill) => {
        return { ...bill, paymentAmount: bill.balance };
      });
      return bills;
    });
  },
  // get bill by id from db per client
  getBillById(bill, org) {
    // console.log("getbillbyid: ",bill,`${config.API_ENDPOINT}/bills/${bill}`)
    return fetch(`${config.API_ENDPOINT}/bills/${bill}`, {
      method: 'GET',
      // body: JSON.stringify(bills),
      headers: {
        // Authorization: `Bearer ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization: org,
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  // resync bills in client db to match bills in books and fetch all from db (per client)
  resyncBills(orgId) {
    return fetch(`${config.API_ENDPOINT}/bills/resync`, {
      method: 'GET',
      // mode: 'cors',
      // body: JSON.stringify(bills),
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        Organization: orgId,
        'content-type': 'application/json',
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  // do I need this function?
  markBillsPaid(bills, org) {
    return fetch(
      `${config.API_ENDPOINT}/org/${config.TEST_BOOKS_ORG_ID}/bills/pay/${bills}`,
      {
        method: 'PATCH',
        body: JSON.stringify(bills),
        headers: {
          // Authorization: `Bearer ${TokenService.getAuthToken()}`,
          'content-type': 'application/json',
          Organization: org,
        },
      }
    ).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
};

export default BillService;
