import config from '../config'
import TokenService from './TokenService'

const TemplateService = {
  async getTemplateSettings(){
    // return {events:dummy.events};
    return fetch(`${config.API_ENDPOINT}/printSettings`, {
      headers: {
        'content-type': 'application/json',
        'Authorization': TokenService.getAuthToken()
      },
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  createTemplate(settings){
    return fetch(`${config.API_ENDPOINT}/printSettings`, {
      method:'POST',
      headers:{
        'content-type':'application/json'
      },
      body:JSON.stringify(settings)
    })
    .then(response => {
      return response.json().then(json => {
        return response.ok ? json : Promise.reject(json.error);
      });
    });
  },
  updateTemplate(settings){
    return fetch(`${config.API_ENDPOINT}/printSettings`, {
      method:'PATCH',
      headers:{
        'content-type':'application/json'
      },
      body:JSON.stringify(settings)
    })
    .then(response => {
      return response.json().then(json => {
        return response.ok ? json : Promise.reject(json.error);
      });
    });
  },
}

export default TemplateService;