import React, { useEffect, useState, useContext } from 'react';
// queued bills component
// unpaid/unqueued bills component
import BillList from '../../components/BillList/BillList';
import InvoiceList from '../../components/InvoiceList/InvoiceList';
import RetainerList from '../../components/RetainerList/RetainerList';
import BillNachasList from '../../components/BillNachasList/BillNachasList';
// import Queue from '../../components/Queue/Queue';
import DynamicModal from '../../components/Modal/Modal';
import './ListView.css';
import Modal from 'react-modal';
import BankModal from '../../components/BankModal/BankModal';
import QueueService from '../../services/QueueService';
import SearchBar from '../../components/SearchBar/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import ZohoService from '../../services/ZohoService';
import BillService from '../../services/BillService';
import PaymentService from '../../services/PaymentService';
import AuthService from '../../services/AuthService';
// import config from '../../config';
import UserContext from '../../contexts/context';
import InvoiceService from '../../services/InvoiceService';
import BillNachasService from '../../services/BillNachasService';
import RetainerService from '../../services/RetainerService';
import TokenService from '../../services/TokenService';
import config from '../../config';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: '30vw',
    height: '60vh',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '500',
    padding: '0',
  },
};

Modal.setAppElement('#root');

export default function ListView() {
  const [bills, setBills] = useState([]);
  const [queue, setQueue] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [billNachas, setBillNachas] = useState([]);
  const [retainers, setRetainers] = useState([]);
  const [filter, setFilter] = useState('Unpaid Bills');
  const [searchTerm, setSearchTerm] = useState('');
  const [selection, setSelection] = useState([]);
  const [invoiceSelection, setInvoiceSelection] = useState([]);
  const [retainerSelection, setRetainerSelection] = useState([]);
  // const [billNachaSelection, setBillNachaSelection] = useState([]);
  const [queueCount, setQueueCount] = useState(0);
  const [queueTotal, setQueueTotal] = useState(0);
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [bankModalIsOpen, setBankModalIsOpen] = React.useState(false);
  const [bankModalType, setBankModalType] = React.useState('');
  const userContext = useContext(UserContext);

  function openModal() {
    setIsOpen(true);
  }

  // function afterOpenModal() {
  //   subtitle.style.background = '#EEE';
  //   subtitle.style.width = '100%';
  // }

  function closeModal() {
    setIsOpen(false);
  }
  // let renderView;

  useEffect(() => {
    console.log('user mount: ', userContext.currentUser);
    if (
      userContext.currentUser &&
      Object.keys(userContext.currentUser).length === 0 &&
      Object.getPrototypeOf(userContext.currentUser) === Object.prototype
    ) {
      AuthService.getUserByID(
        userContext.currentUser.id || userContext.userId
      ).then((user) => {
        console.log('69 - ', user);
        userContext.setCurrentUser(user);
      });
    }
    QueueService.getAllBills(
      userContext.currentUser.organizationId || userContext.currentOrg
    )
      .then(async (bills) => {
        // let qTotal = 0;
        if (bills !== undefined && bills.bills.length !== 0) {
          // console.log("Bill queue:", bills.bills)
          let qCount = bills.bills !== undefined ? bills.bills.length : 0;
          let bAmounts = await bills.bills.map((bill) => {
            return BillService.getBillById(
              bill.id,
              userContext.currentUser.organizationId || userContext.currentOrg
            ).then((b) => {
              if ('bill' in b) {
                return Number(b.bill.balance);
              }
            });
          });
          Promise.all(bAmounts).then((results) => {
            // console.log("AMOUNT ARRAY: ", results)
            let qTotal = results.reduce(
              (previousValue, currentValue) => previousValue + currentValue
            );
            // console.log("QTot:", qTotal)
            setQueueTotal(qTotal.toFixed(2));
            setQueueCount(qCount);
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    QueueService.getAllBills(
      userContext.currentUser.organizationId || userContext.currentOrg
    ).then(async (bills) => {
      // let qTotal = 0;
      console.log('137', bills);
      if (bills !== undefined && bills.bills.length !== 0) {
        // console.log("Bill queue:", bills.bills)
        let qCount = bills.bills !== undefined ? bills.bills.length : 0;
        let bAmounts = await bills.bills.map((bill) => {
          if ('balance' in bill) {
            return BillService.getBillById(
              bill.id,
              userContext.currentUser.organizationId || userContext.currentOrg
            ).then((b) => {
              if ('bill' in b) {
                console.log('146', b);
                return b.bill.balance != null ? Number(b.bill.balance) : 0;
              }
            });
          }
        });
        Promise.all(bAmounts).then((results) => {
          if (results.length !== 0) {
            // console.log("AMOUNT ARRAY: ", results)
            // let initialValue = 0
            let qTotal = results.reduce(
              (previousValue, currentValue) => previousValue + currentValue
            );
            // console.log("QTot:", qTotal)
            if (qTotal !== queueTotal) setQueueTotal(qTotal.toFixed(2));
            if (qCount !== queueCount) setQueueCount(qCount);
          }
        });
      }
    });
  }, [queue]);

  useEffect(() => {
    async function getDataFromIds(bills) {
      // const reponse = await fetch()
      const arrOfPromises = bills.bills.map((bill) => {
        console.log('getting data from id: ', bill);
        return BillService.getBillById(
          bill.id,
          userContext.currentUser.organizationId || userContext.currentOrg
        );
      });
      return Promise.all(arrOfPromises);
    }
    console.log('FILTER: ', filter);

    if (filter === 'Bills in Queue') {
      QueueService.getAllBills(
        userContext.currentUser.organizationId || userContext.currentOrg
      ).then((billResp) => {
        console.log(billResp);
        getDataFromIds(billResp).then((resp) => {
          console.log('RESP: ', resp);
          const convertedQueue = resp.map((bills) => bills.bill);
          let qTotal = 0;
          const qCount =
            convertedQueue !== undefined ? billResp.bills.length : 0;

          convertedQueue.forEach((bill) => {
            qTotal += Number(bill.balance);
            let paymentAmount;
            console.log('BILLS', bills);
            // bills.forEach((b) => {
            //   if (bill.id == b.id) {
            //     bill.paymentAmount = b.paymentAmount;
            //   }
            // });
          });
          if (queue !== convertedQueue) setQueue(convertedQueue);
          if (queueTotal !== qTotal) setQueueTotal(qTotal.toFixed(2));
          if (queueCount !== qCount) setQueueCount(qCount);
          // setBills(convertedQueue);
        });
      });
    }
    if (filter === 'Invoices') {
      InvoiceService.getInvoices(
        userContext.currentUser.organizationId || userContext.currentOrg
      ).then((invoices) => {
        var searchedInvoices = searchInvoices(invoices);
        setInvoices(searchedInvoices);
      });
    } else if (filter === 'Bill Nachas') {
      BillNachasService.getBillNachas(
        userContext.currentUser.organizationId || userContext.currentOrg
      ).then((nachas) => {
        setBillNachas(nachas);
      });
    } else if (filter === 'Retainer Invoices') {
      RetainerService.getRetainerInvoices(
        userContext.currentUser.organizationId || userContext.currentOrg
      ).then((retainers) => {
        var searchedRetainers = searchRetainers(retainers.retainerinvoices);
        setRetainers(searchedRetainers);
      });
    } else {
      // get unpaid bills from zoho books based on user context data, i.e. we should grab user data (orgID, userID)
      // how do we get client ID and Secret and auth token?
      console.log(
        'GET ALL BILLS (FILTER): ',
        userContext.currentUser.organizationId || userContext.currentOrg
      );
      BillService.getBills(
        userContext.currentUser.organizationId || userContext.currentOrg
      ).then((bills) => {
        var searchedBills = searchBills(bills);
        setBills(searchedBills);
      });
    }
  }, [filter, searchTerm]);

  const searchInvoices = (invoices) => {
    var lowerSearchTerm = searchTerm.toLowerCase();
    if (lowerSearchTerm.replace(/\s/g, '') === '') {
      return invoices;
    }
    var searchedInvoices = invoices.filter((invoice) => {
      if (
        invoice.customer.toLowerCase().includes(lowerSearchTerm) ||
        invoice.status.toLowerCase().includes(lowerSearchTerm) ||
        invoice.balance.includes(lowerSearchTerm) ||
        invoice.invoice_number.toLowerCase().includes(lowerSearchTerm)
      ) {
        return invoice;
      }
    });
    return searchedInvoices;
  };

  const searchRetainers = (retainers) => {
    var lowerSearchTerm = searchTerm.toLowerCase();
    if (lowerSearchTerm.replace(/\s/g, '') === '') {
      return retainers;
    }
    var searchedRetainers = retainers.filter((retainer) => {
      if (
        retainer.customer.toLowerCase().includes(lowerSearchTerm) ||
        retainer.status.toLowerCase().includes(lowerSearchTerm) ||
        retainer.balance.includes(lowerSearchTerm) ||
        retainer.retainer_number.toLowerCase().includes(lowerSearchTerm)
      ) {
        return retainer;
      }
    });
    return searchedRetainers;
  };

  const searchBills = (bills) => {
    var lowerSearchTerm = searchTerm.toLowerCase();
    if (lowerSearchTerm.replace(/\s/g, '') === '') {
      return bills;
    }
    var searchedBills = bills.filter((bill) => {
      if (
        bill.vendor.toLowerCase().includes(lowerSearchTerm) ||
        bill.status.toLowerCase().includes(lowerSearchTerm) ||
        bill.bill_number.includes(lowerSearchTerm) ||
        bill.status.includes(lowerSearchTerm) ||
        bill.balance.includes(lowerSearchTerm) ||
        bill.reference_number.toLowerCase().includes(lowerSearchTerm)
      ) {
        return bill;
      }
    });
    return searchedBills;
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  const handleSelection = (childSelection) => {
    setSelection(childSelection);
  };

  const handleInvoiceSelection = (childSelection) => {
    setInvoiceSelection(childSelection);
  };

  const handleRetainerSelection = (childSelection) => {
    setRetainerSelection(childSelection);
  };

  const addBilltoQueue = async () => {
    console.log('current selection: ', selection);
    // add whatever is in selection state to Queue in DB
    for await (const bID of selection) {
      let paymentAmount;
      bills.forEach((bill) => {
        if (bill.id == bID) {
          paymentAmount = bill.paymentAmount;
        }
      });
      // BillService.getBillById(bID).then(bill => {
      console.log('bID: ', bID);
      console.log(paymentAmount);
      QueueService.postBill(
        bID,
        paymentAmount,
        userContext.currentUser.organizationId || userContext.currentOrg
      ).then((bill) => {
        setSelection([]);
        setFilter('Bills in Queue');
      });
    }
  };

  const removeBillFromQueue = async () => {
    // run function to remove selection state ID's from DB
    // selection.map(bill => QueueService.deleteBill(bill, userContext.currentUser.organizationId ||userContext.currentOrg))
    for await (const bID of selection) {
      QueueService.dequeueBill(
        bID,
        userContext.currentUser.organizationId || userContext.currentOrg
      ).then((bill) => {
        setSelection([]);
        setFilter('');
        setFilter('Bills in Queue');
      });
    }
  };

  const handleReSync = () => {
    BillService.getBills(
      userContext.currentUser.organizationId || userContext.currentOrg
    ).then((bills) => {
      setBills(bills);
    });
    // resync invoices
    InvoiceService.getInvoices(
      userContext.currentUser.organizationId || userContext.currentOrg
    ).then((invoices) => {
      setInvoices(invoices);
    });
    // resync retainers
    RetainerService.getRetainerInvoices(
      userContext.currentUser.organizationId || userContext.currentOrg
    ).then((retainers) => {
      setRetainers(retainers.retainerinvoices);
    });
  };

  // bills
  const payByAch = async (bank, date, queued) => {
    console.log('PAY BY ACH!');

    if (queued === undefined || queued.length === 0) {
      alert('Your queue is empty!');
    } else {
      let vendorMap = {};
      queued.forEach((bill) => {
        bill.amount = bill.paymentAmount;
        let vendor = bill.vendor;
        if (vendorMap.hasOwnProperty(vendor)) {
          vendorMap[vendor] = [...vendorMap[vendor], bill];
        } else {
          vendorMap[vendor] = new Array(bill);
        }
      });
      const keys = Object.keys(vendorMap);

      PaymentService.makeAchPayment(
        vendorMap,
        userContext.currentUser,
        userContext.currentUser.organizationId || userContext.currentOrg,
        bank,
        date
      )
        .then(async (res) => {
          return await PaymentService.makeBillPayments(
            queued,
            date,
            userContext.currentUser,
            userContext.currentUser.organizationId || userContext.currentOrg
          );
        })
        .then((booksResp) => {
          console.log('375 booksResp', booksResp);
        })
        .then(() => {
          setTimeout(() => {
            setSelection([]);
            setFilter('Unpaid Bills');
          }, 1500);
          setBankModalIsOpen(false);
        });
    }
  };

  // invoices
  const collectByAch = async (bank, date, invoices) => {
    // console.log('COLLECT BY ACH!');
    // console.log(bank);

    // InvoiceService.getInvoices(
    //   userContext.currentUser.organizationId || userContext.currentOrg
    // )
    // .then(async (invoices) => {
    const invList = invoices.filter((inv) => inv.batched === true);
    if (invList === undefined || invList.length === 0) {
      alert('Your batch is empty!');
    } else {
      // add grouping function here?
      let vendorMap = {};
      invList.forEach((invoice) => {
        let customer = invoice.customer;
        invoice.amount = invoice.paymentAmount;
        if (vendorMap.hasOwnProperty(customer)) {
          vendorMap[customer] = [...vendorMap[customer], invoice];
        } else {
          vendorMap[customer] = new Array(invoice);
        }
      });
      // start vendor group loop
      // const keys = Object.keys(vendorMap);
      // for await (const customer of keys) {
      PaymentService.collectAchPayment(
        vendorMap,
        userContext.currentUser,
        config.ORG_ID,
        bank,
        date
      )
        .then(async (res) => {
          return await PaymentService.makeInvoicePayments(
            invList,
            userContext.currentUser,
            userContext.currentUser.organizationId || userContext.currentOrg
          );
        })
        // }
        .then(() => {
          setTimeout(() => {
            setSelection([]);
            setFilter('Invoices');
          }, 1500);
          setBankModalIsOpen(false);
        });
      // })
    }
  };

  // retainers
  const collectRetainersByAch = async (bank, date) => {
    RetainerService.getRetainerInvoices(
      userContext.currentUser.organizationId || userContext.currentOrg
    )
      .then(async (retainers) => {
        const retainersList = retainers.retainerinvoices.filter(
          (retainer) => retainer.batched === true
        );
        if (retainersList === undefined || retainersList.length === 0) {
          alert('Your batch is empty!');
        } else {
          let vendorMap = {};
          retainersList.forEach((retainer) => {
            let customer = retainer.customer;
            if (vendorMap.hasOwnProperty(customer)) {
              vendorMap[customer] = [...vendorMap[customer], retainer];
            } else {
              vendorMap[customer] = new Array(retainer);
            }
          });
          // start vendor group loop
          PaymentService.collectRetainerAchPayment(
            vendorMap,
            userContext.currentUser,
            config.ORG_ID,
            bank,
            date
          )
            .then(async (res) => {
              return await PaymentService.makeRetainerPayments(
                retainersList,
                userContext.currentUser,
                userContext.currentUser.organizationId || userContext.currentOrg
              );
            })
            .then((res) => {
              console.log(res);
            });
        }
      })
      .then(() => {
        setTimeout(() => {
          setSelection([]);
          setFilter('Retainer Invoices');
        }, 1500);
        setBankModalIsOpen(false);
      });
  };

  const addInvoiceToBatch = async () => {
    // const filteredList = invoiceSelection.filter(inv => inv.batched === false)
    for await (const invId of invoiceSelection) {
      let paymentAmount;
      invoices.forEach((invoice) => {
        if (invoice.id == invId) {
          paymentAmount = invoice.paymentAmount;
        }
      });
      InvoiceService.updateInvoiceBatch(
        invId,
        paymentAmount,
        userContext.currentUser.organizationId || userContext.currentOrg
      ).then((inv) => {
        InvoiceService.getInvoices(
          userContext.currentUser.organizationId || userContext.currentOrg
        ).then(() => {
          setFilter('');
          setFilter('Invoices');
        });
      });
    }
  };
  // for each checked invoice, mark 'batched' as true and send to backend to update db
  // if selected invoices are already true, skip adding them to update list
  const removeInvoiceFromBatch = async () => {
    // const filteredList = invoiceSelection.filter(inv => inv.batched === false)
    for await (const invId of invoiceSelection) {
      InvoiceService.debatchInvoice(
        invId,
        userContext.currentUser.organizationId || userContext.currentOrg
      ).then((inv) => {
        InvoiceService.getInvoices(
          userContext.currentUser.organizationId || userContext.currentOrg
        ).then(() => {
          setFilter('');
          setFilter('Invoices');
        });
      });
    }
  };

  const addRetainerInvoiceToBatch = async () => {
    for await (const id of retainerSelection) {
      RetainerService.updateRetainerBatch(
        id,
        userContext.currentUser.organizationId || userContext.currentOrg
      ).then(() => {
        RetainerService.getRetainerInvoices(
          userContext.currentUser.organizationId || userContext.currentOrg
        ).then(() => {
          setFilter('');
          setFilter('Retainer Invoices');
        });
      });
    }
  };

  // for each checked retainer, mark 'batched' as true and send to backend to update db
  // if selected retainers are already true, skip adding them to update list
  const removeRetainerInvoiceFromBatch = async () => {
    for await (const id of retainerSelection) {
      RetainerService.updateRetainerBatch(
        id,
        userContext.currentUser.organizationId || userContext.currentOrg
      ).then((retainer) => {
        RetainerService.getRetainerInvoices(
          userContext.currentUser.organizationId || userContext.currentOrg
        ).then(() => {
          setFilter('');
          setFilter('Retainer Invoices');
        });
      });
    }
  };

  const options = [
    {
      label: 'Bills in Queue',
      value: 'Bills in Queue',
    },
    {
      label: 'Unpaid Bills',
      value: 'Unpaid Bills',
    },
    {
      label: 'Invoices',
      value: 'Invoices',
    },
    {
      label: 'Retainer Invoices',
      value: 'Retainer Invoices',
    },
    // {
    //   label: 'Bill Nachas',
    //   value: 'Bill Nachas',
    // },
  ];

  return (
    <section className='report'>
      <div className='report-header'>
        <Modal
          isOpen={modalIsOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel='Print Flow'
        >
          <DynamicModal closeModal={closeModal}></DynamicModal>
        </Modal>
        {bankModalIsOpen && (
          <BankModal
            closeModal={setBankModalIsOpen}
            payByAch={payByAch}
            collectByAch={collectByAch}
            collectRetainersByAch={collectRetainersByAch}
            type={bankModalType}
            queue={queue}
            invoices={invoices}
          />
        )}
        <form>
          <select
            className='filter-select'
            value={filter}
            onChange={handleFilter}
          >
            {options.map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </form>
        <div className='button-bar'>
          <SearchBar onSearch={setSearchTerm} />
          {filter === 'Bill Nachas' ? (
            ''
          ) : (
            <>
              <p>
                <span>{queueCount} </span>
                {queueCount === 1
                  ? 'Bill in Print Queue'
                  : 'Bills in Print Queue'}
              </p>
              <p>
                Total in Queue: <span>${queueTotal}</span>
              </p>
            </>
          )}
          {filter === 'Bills in Queue' ? (
            <button
              onClick={() => removeBillFromQueue()}
              className='btn remove-button'
            >
              Remove Bill
            </button>
          ) : filter === 'Unpaid Bills' ? (
            <button onClick={() => addBilltoQueue()} className='btn add-button'>
              Add to Queue
            </button>
          ) : (
            ''
          )}
          {filter === 'Invoices' && (
            <>
              {' '}
              <button
                onClick={() => removeInvoiceFromBatch()}
                className='btn remove-button'
              >
                Remove From Batch
              </button>{' '}
              <button
                onClick={() => addInvoiceToBatch()}
                className='btn add-button'
              >
                Add to Batch
              </button>
            </>
          )}
          {filter === 'Retainer Invoices' ? (
            <>
              {' '}
              <button
                onClick={() => removeRetainerInvoiceFromBatch()}
                className='btn remove-button'
              >
                Remove From Batch
              </button>{' '}
              <button
                onClick={() => addRetainerInvoiceToBatch()}
                className='btn add-button'
              >
                Add to Batch
              </button>
            </>
          ) : (
            ''
          )}
          {/* {queueCount > 0 && filter !== 'Invoices' ? <button className="btn pay-button" onClick={openModal}>Pay by Check</button> : ''} */}
          {queueCount > 0 &&
          filter !== 'Invoices' &&
          filter === 'Bills in Queue' ? (
            <button
              className='btn pay-button'
              onClick={() => {
                setBankModalType('bills');
                setBankModalIsOpen(true);
              }}
            >
              Pay by ACH
            </button>
          ) : (
            ''
          )}
          {invoices.length > 0 && filter === 'Invoices' ? (
            <button
              className='btn pay-button'
              onClick={() => {
                setBankModalType('invoices');
                setBankModalIsOpen(true);
              }}
            >
              Collect by ACH
            </button>
          ) : (
            ''
          )}
          {retainers.length > 0 && filter === 'Retainer Invoices' ? (
            <button
              className='btn pay-button'
              onClick={() => {
                setBankModalType('retainers');
                setBankModalIsOpen(true);
              }}
            >
              Collect by ACH
            </button>
          ) : (
            ''
          )}
          <button className='btn add-button' onClick={() => handleReSync()}>
            <FontAwesomeIcon icon='sync' color='white' />
          </button>
        </div>
      </div>
      {filter === 'Bills in Queue' ? (
        <BillList
          list='queue'
          bills={queue}
          handleSelection={handleSelection}
        />
      ) : filter === 'Unpaid Bills' ? (
        <BillList
          list='unpaid'
          bills={bills}
          handleSelection={handleSelection}
        />
      ) : filter === 'Bill Nachas' ? (
        <BillNachasList
          billNachas={billNachas}
          handleSelection={handleSelection}
        />
      ) : filter === 'Retainer Invoices' ? (
        <RetainerList
          retainers={retainers}
          handleSelection={handleRetainerSelection}
        />
      ) : (
        <InvoiceList
          invoices={invoices}
          handleSelection={handleInvoiceSelection}
        />
      )}
    </section>
  );
}
