import { useContext } from 'react';
import config from '../config';
import QueueService from './QueueService';
import TokenService from './TokenService';

const PaymentService = {
  // get bills from DB per client
  getPayments(org) {
    return fetch(`${config.API_ENDPOINT}/payments`, {
      method: 'GET',
      // body: JSON.stringify(bills),
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization: org,
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  makePayments(bills, org) {
    return fetch(`${config.API_ENDPOINT}/payments`, {
      method: 'POST',
      body: JSON.stringify(bills),
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization: org,
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },

  async makeBillPayments(bills, pickdate, user, org) {
    const billsList = await bills.map((bill) => {
      const date = bill.date.split('T')[0];
      return {
        id: bill.id,
        booksID: bill.booksID,
        vendorID: bill.vendorID,
        date: date,
        amount: bill.paymentAmount,
        reference_number: bill.reference_number,
        balance: bill.balance,
        // user: user,
      };
    });

    const data = { bills: billsList, user };

    return fetch(`${config.API_ENDPOINT}/payments/paybills`, {
      method: 'POST',
      body: JSON.stringify(data),
      mode: 'cors',

      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        Organization: org,
        'Access-Control-Allow-Origin': '*',
        // 'accept':'text/plain',
        'content-type': 'application/json',
      },
    });
  },

  async makeInvoicePayments(invoices, user, org) {
    const invoicesList = await invoices.map((invoice) => {
      const date = invoice.date.split('T')[0];
      return {
        id: invoice.id,
        booksID: invoice.booksID,
        customerID: invoice.customer_id,
        date: date,
        amount: invoice.paymentAmount,
        reference_number: invoice.reference_number,
        // user: user,
      };
    });

    const data = { invoices: invoicesList, user };

    return fetch(`${config.API_ENDPOINT}/payments/payinvoices`, {
      method: 'POST',
      body: JSON.stringify(data),
      mode: 'cors',

      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        Organization: org,
        'Access-Control-Allow-Origin': '*',
        // 'accept':'text/plain',
        'content-type': 'application/json',
      },
    });
  },

  async makeRetainerPayments(retainers, user, org) {
    const retainersList = await retainers.map((retainer) => {
      const date = retainer.date.split('T')[0];
      return {
        id: retainer.id,
        booksID: retainer.booksID,
        customerID: retainer.customer_id,
        date: date,
        balance: parseFloat(retainer.balance),
        amount: parseFloat(retainer.amount),
        reference_number: retainer.reference_number,
      };
    });

    const data = { retainers: retainersList, user };

    return fetch(`${config.API_ENDPOINT}/payments/payretainers`, {
      method: 'POST',
      body: JSON.stringify(data),
      mode: 'cors',
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        Organization: org,
        'Access-Control-Allow-Origin': '*',
        // 'accept':'text/plain',
        'content-type': 'application/json',
      },
    });
  },

  async makeAchPayment(queue, user, org, bank, nachaDate) {
    const nachaObj = {
      // current user ID/organization id?
      user: user,
      vendorMap: queue,
      // vendorId: queue[0].vendorID ?? '0',
      file: {
        // Books contact -> custom field -> routing number
        immediateDestination: '081000032',
        // books contact -> custom field  -> bank name
        immediateDestinationName: 'JPMorgan Chase',
        // books contact (vendor) name or company name?
        immediateOriginName: 'Zbrains',
        // filled with 8 blank spaces accoring to Chase Nacha Spec
        referenceCode: '        ',
      },
      bank: bank,
      date: nachaDate,
    };

    console.log('line 157');
    console.log(nachaObj);

    return fetch(`${config.API_ENDPOINT}/ach/bill`, {
      method: 'POST',
      body: JSON.stringify(nachaObj),
      mode: 'cors',
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        Organization: org,
        // 'accept':'text/plain',
        'content-type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => {
            throw error;
          });
        }
        setTimeout(() => {
          this.getNACHAFile(org);
        }, 15000);
        return;
      })
      .catch((e) => {
        return 'Error making ACH Payment.';
      });
  },

  async collectAchPayment(queue, user, org, bank, nachaDate) {
    console.log('bank', bank);

    const nachaObj = {
      user: user,
      customerMap: queue,
      bank: bank,
      date: nachaDate,
    };

    return fetch(`${config.API_ENDPOINT}/ach/invoice`, {
      method: 'POST',
      body: JSON.stringify(nachaObj),
      mode: 'cors',
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        Organization: org,
        // 'accept':'text/plain',
        'content-type': 'application/json',
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      setTimeout(() => {
        this.getNACHAFile(org);
      }, 15000);
      return;
    });
  },

  async collectRetainerAchPayment(queue, user, org, bank, nachaDate) {
    console.log('bank', bank);

    const nachaObj = {
      user: user,
      customerMap: queue,
      bank: bank,
      date: nachaDate,
    };

    return fetch(`${config.API_ENDPOINT}/ach/retainer`, {
      method: 'POST',
      body: JSON.stringify(nachaObj),
      mode: 'cors',
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        Organization: org,
        // 'accept':'text/plain',
        'content-type': 'application/json',
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      setTimeout(() => {
        this.getNACHAFile(org);
      }, 15000);
      return;
    });
  },

  getNACHAFile(orgID) {
    return fetch(`${config.API_ENDPOINT}/ach/file`, {
      method: 'GET',
      // body: JSON.stringify(nachaObj),
      mode: 'cors',
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        Organization: orgID,
        // 'accept':'text/plain',
        'content-type': 'application/json',
      },
    })
      .then((resp) =>
        resp.status !== 503 ? resp.blob() : console.log('503: ', resp)
      )
      .then((blob) => {
        console.log('blob', blob);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        const currentDate = new Date().toLocaleDateString();
        a.download = `Nacha_${currentDate}.txt`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert('your file has downloaded!');
      })
      .catch(() => alert('Something went wrong.'));
  },
  // get bill by id from db per client
  getBillById(pid, org) {
    // console.log("getbillbyid: ",bill,`${config.API_ENDPOINT}/payments/${pid}`)
    return fetch(`${config.API_ENDPOINT}/payments/${pid}`, {
      method: 'GET',
      // body: JSON.stringify(bills),
      headers: {
        // Authorization: `Bearer ${TokenService.getAuthToken()}`,
        Organization: org,
        'content-type': 'application/json',
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  // resync bills in client db to match bills in books and fetch all from db (per client)
  resyncPayments(org) {
    return fetch(`${config.API_ENDPOINT}/payments/resync`, {
      method: 'GET',
      // body: JSON.stringify(bills),
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        Organization: org,
        'content-type': 'application/json',
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  getOrgBankAccounts(org) {
    return fetch(`${config.API_ENDPOINT}/ach/banks`, {
      method: 'get',
      mode: 'cors',
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        Organization: org,
        'content-type': 'application/json',
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  // markBillsPaid(bills){
  //   return fetch(`${config.API_ENDPOINT}/org/${config.TEST_BOOKS_ORG_ID}/bills/pay/${bills}`, {
  //     method: 'PATCH',
  //     body: JSON.stringify(bills),
  //     headers: {
  //       // Authorization: `Bearer ${TokenService.getAuthToken()}`,
  //       'content-type': 'application/json',
  //     },
  //   }).then((res) => {
  //     if (!res.ok) {
  //       return res.json().then((error) => {
  //         throw error;
  //       });
  //     }
  //     return res.json();
  //   });
  // }
};

export default PaymentService;
