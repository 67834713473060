import React, { Component } from 'react'
import numberToWords from 'number-to-words'
import './ComponentToPrint.css'

export class ComponentToPrint extends Component {
  render() {
    const { date, bill, reference, vendor, balance, amount } = this.props.bill
    const { company_name, street, city, state, zip } = this.props.company
    const { checkLine1, checkLine2, checkLine3, checkLine4 } = this.props.positionSettings
    const billRow = this.props.bills.length > 10 ? this.props.bills.map(bill => {
      return (
        <tr>
          <td>{bill.date}</td>
          <td>{bill.bill}</td>
          <td className="text-right">${bill.amount}</td>
          <td className="text-right">${bill.balance}</td>
        </tr>
      )
    }) : 'See Extended Stub Report'
    console.log(checkLine1)
    return (
      <section className="PrintPage Group_1">
        <div className="checkTier">
          {/* check line 1: address block and date */}
          <div className="checkLine1 flex-end" style={checkLine1}>
            {/* <div className="addressBlock">
              <h5>{company_name || 'ZBrains'}</h5>
              <p>{street || '123 Test Dr.'}</p>
              <p>{city || 'Pasadena'}, {state || 'CA'} {zip || '91102'}</p>
            </div> */}
            <p>{date}</p>
          </div>
          {/* check line 2: pay to the order of and amount */}
          <div className="checkLine1" style={checkLine2}>
            <p className="payToTheOrder">{vendor}</p>
            <p>{balance}</p>
          </div>
          {/* check line 3: written out amount */}
          <div className="checkLine1" style={checkLine3}>
            <p>{amount !== undefined ? numberToWords.toWords(amount) : ''}</p>
          </div>
          {/* check line 4: memo */}
          <div className="checkLine1" style={checkLine4}>
            <p>
              {reference}
            </p>
          </div>
        </div>
        <div className="checkStub1">
          <div class="checkStubPreHeader">
            <p>{vendor}</p>
            <p>{date}</p>
          </div>
          <table className="stubTable">
            <thead>
              <tr className="checkStubHeader">
                <th>Bill Date</th>
                <th>Bill Number</th>
                <th className="text-right">Amount</th>
                <th className="text-right">Paid</th>
              </tr>
            </thead>
            <tbody>
              {billRow}
            </tbody>
          </table>
        </div>
        <div className="checkStub2">
          <div class="checkStubPreHeader">
            <p>{vendor}</p>
            <p>{date}</p>
          </div>
          <table className="stubTable">
            <tr className="checkStubHeader">
              <th>Bill Date</th>
              <th>Bill Number</th>
              <th className="text-right">Amount</th>
              <th className="text-right">Paid</th>
            </tr>
            {billRow}
          </table>
        </div>
      </section>
    )
  }
}

export default ComponentToPrint;