import React, { useEffect, useState,useContext } from 'react'
import UserContext from '../../contexts/context'
import BillNachasService from '../../services/BillNachasService';
import './BillNachas.css'
import FormatFunctions from '../Utils/FormatFunctions';
import config from '../../config';

export default function BillNachasList(props) {
  const userContext = useContext(UserContext);
  const [billNachas, setBillNachas] = useState([]);
  const [billNachasList, setBillNachasList] = useState([]);

  useEffect(()=>{
    if (billNachasList != props.billNachas) {
      setBillNachasList(props.billNachas);
    }
  },[props.billNachas])
  
  useEffect(() => {
    if (billNachasList.length > 0 && typeof billNachasList[0] !== undefined) {
      setBillNachas(
        billNachasList.map((nacha) => {
          const dateCreated = nacha.created_time;
          const companyName = nacha.batch.companyName;
          if (Array.isArray(nacha.details) && nacha.details.length > 0) {
            return nacha.details.map((bill) => {
              return (
                <tr key={bill.idNumber}>
                  <td className='text-left'>
                    {new Date(dateCreated).toLocaleDateString()}
                  </td>
                  <td className='text-left'>
                    {companyName}
                  </td>
                  <td className='text-left'>
                    {FormatFunctions.formatDollars(bill.amount)}
                  </td>
                  <td>
                    <button className='download-button' onClick={() => downloadAsTxt(nacha)}></button>
                  </td> 
                </tr>
              )
            })
          }
        }) 
      )
    }
  }, [billNachasList]);

  const downloadAsTxt = (nacha) => {
    console.log(nacha);
    console.log('CONFIG ORG', config.ORG_ID);
    BillNachasService.getBillHistory(nacha, userContext.currentUser, config.ORG_ID)
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((e) => {
        console.log(e);
      })
    // URL 
    // GET /billsnacha
    // gives ALL records for table 
    // user, batch, 
    /*
    {
    "billsnacha": [
        {
            "id": 1,
            "user": {},
            "file": {},
            "batch": {},
            "details": {},
            "bills": null,
            "created_time": null
        }
    ]
}
*/

// send to fetch(`${config.API_ENDPOINT}/ach/bill`, 
// getNACHAFile(orgID)
// call URL /ach/bill
/*
name is companyName
amount is from details
bank remove
send the whole object to the create nacha file endpoint

show other details on dropdown (? optional, see where you get)

// ach/billhistory
// ach/invoicehistory for download

*/
    
  }

  return (
    <table className="listTable">
      <thead>
        <tr>
          <th className="text-left">DATE CREATED</th>
          <th className="text-left">VENDOR</th>
          <th className="text-left">AMOUNT</th>
          <th className="text-left">DOWNLOAD</th>
        </tr>
      </thead>
      <tbody>
        {billNachas}
      </tbody>
    </table>
  )
}
