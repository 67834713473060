import React, { useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import logo from '../../logo.svg';
import './NavBar.css';
import UserContext from '../../contexts/context';

export default function NavBar() {
  const context = useContext(UserContext);
  const org = context.currentOrg;
  return (
    <nav>
      <NavLink to='/' className='headline removeTextDecoration f-sb h100'>
        <p className='logo-text mr'>ACH solution by</p>
        {/* <img src={logo} alt="ZBrains" /> */}
        <img
          className='logoImg'
          src={process.env.PUBLIC_URL + '/logo192.png'}
          alt='Zbrains'
        />
        <h4 className='logo-text'>Brains</h4>
      </NavLink>
      {org}
      <NavLink className='headline' to='/settings'>
        <FontAwesomeIcon icon='cog' color='white' />
      </NavLink>
    </nav>
  );
}
