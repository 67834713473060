import React, { useContext } from 'react';
import './LoginView.css';
import UserContext from '../../contexts/context';

export default function LoginView() {
  const userContext = useContext(UserContext);
  return (
    <div className='loginCard absoluteCenter'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '20%',
          alignItems: 'center',
        }}
        className='headline'
      >
        <img
          className='loginLogoImg'
          src={process.env.PUBLIC_URL + '/logo192.png'}
          alt='Zbrains'
        />
        <h4 className='logo-text-black'>Brains</h4>
      </div>
      <p>Log in to access the ZBrains® ACH Solution</p>
      {userContext.loginError !== '' ? (
        <p style={{ color: 'red' }}>{userContext.loginError}</p>
      ) : (
        ''
      )}
      <a
        className='btn add-button removeTextDecoration btn-margin'
        href='https://accounts.zoho.com/oauth/v2/auth?client_id=1000.UCBUTZ063UKTI9FA0F0580H7NEQMTY&response_type=token&scope=AaaServer.profile.Read,email,ZohoBooks.fullaccess.all&redirect_uri=https://check.fieldtech.io/auth'
      >
        Log In
      </a>
    </div>
  );
}

// 1000.UCBUTZ063UKTI9FA0F0580H7NEQMTY = live +
// 1000.8BOGZW2VF45UOH1JX4VS26ZRU64QUZ = local

// http://localhost:3000/auth = local/
// https://check.fieldtech.io/auth = live
