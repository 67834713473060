import config from '../config';
import TokenService from './TokenService';

const RetainerService = {
  // get retainers from DB per client
  getRetainerInvoices(org){
    return fetch(`${config.API_ENDPOINT}/retainers`, {
      method: 'GET',
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization:org
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  // get retainer by id from db per client
  getRetainerInvoiceById(retainer,org){
    return fetch(`${config.API_ENDPOINT}/retainers/${retainer}`, {
      method: 'GET',
      headers: {
        // Authorization: `Bearer ${TokenService.getAuthToken()}`,
        Organization:org,
        'content-type': 'application/json',
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  // resync retainers in client db to match retainers in books and fetch all from db (per client)
  resyncRetainerInvoices(orgId) {
    return fetch(`${config.API_ENDPOINT}/retainers/resync`, {
      method: 'GET',
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        Organization: orgId,
        'content-type': 'application/json',
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  updateRetainerBatch(id,org){
    return fetch(`${config.API_ENDPOINT}/retainers/update `, {
      method: 'PATCH',
      body: JSON.stringify({retainerinvoice:id}),
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization:org
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  markRetainersPaid(retainers,org){
    return fetch(`${config.API_ENDPOINT}/org/${config.TEST_BOOKS_ORG_ID}/retainers/pay/${retainers}`, {
      method: 'PATCH',
      body: JSON.stringify(retainers),
      headers: {
        // Authorization: `Bearer ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization:org
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  }
}

export default RetainerService;