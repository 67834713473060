import React, { useEffect, useState, useContext } from 'react';

export default function InvoiceList(props) {
  const [sortAscending, setSortAscending] = useState(true);
  const [sortValue, setSortValue] = useState('');
  const [invoices, setInvoices] = useState([]);
  const [invoiceList, setInvoiceList] = useState();
  const [selectedInvoices, setSelection] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (invoiceList !== props.invoices) {
      var batched = [];
      const invs = props.invoices
        .map((inv) => {
          inv.selected = false;
          return inv;
        })
        .filter((inv) => {
          if (inv.batched) {
            batched.push(inv);
          } else {
            return inv;
          }
        });

      setInvoiceList([...batched, ...invs]);
    }
  }, [props.invoices]);

  // useEffect(()=>{
  //   if(props.queueAction === true && selectedInvoices.length > 0){
  //     QueueService.postInvoice(selectedInvoices)
  //   }
  //   props.handleQueueUpdate()
  // },[props.queueAction])
  // useEffect(() => {
  //   // console.log(props)
  //   if (props.list === "queue") {
  //     QueueService.getInvoices()
  //       .then(invoices => {
  //         setInvoiceList(invoices.invoices)
  //       })
  //     // test
  //     // setInvoiceList(Store.queue)
  //   } else {
  //     // get unpaid invoices from zoho books based on user context data, i.e. we should grab user data (orgID, userID)
  //     // how do we get client ID and Secret and auth token?
  //     // console.log("GET ALL BILLS USER: ",userContext.currentUser)
  //     InvoiceService.getInvoices()
  //       .then(invoices => {
  //         console.log("set invoices: ",invoices)
  //         setInvoiceList(invoices.invoices)
  //       })
  //     // test
  //     if (props.resync === false) {
  //       // setInvoiceList(Store.invoices)
  //       console.log(invoiceList)
  //     }
  //   }
  // }, [props])

  useEffect(() => {
    console.log(invoiceList);
  }, []);

  const handleMassSelect = (e) => {
    // console.log(e.target)
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectAll === true) {
      invoiceList.forEach((invoice) => {
        updateSelectedInvoices(invoice.id);
      });
    }
    setSelection([]);
  }, [selectAll]);

  useEffect(() => {
    sortRows(sortValue);
  }, [sortAscending]);

  const sortRows = (value) => {
    setSortValue(value);
    var sorted = invoiceList;
    console.log(sorted);

    if (value === 'customer') {
      sorted = invoiceList.sort((invoiceA, invoiceB) => {
        if (sortAscending) {
          var customerA = invoiceA.customer.toUpperCase();
          var customerB = invoiceB.customer.toUpperCase();
          return customerA < customerB ? -1 : customerA > customerB ? 1 : 0;
        } else {
          var customerA = invoiceA.customer.toUpperCase();
          var customerB = invoiceB.customer.toUpperCase();
          return customerA > customerB ? -1 : customerA < customerB ? 1 : 0;
        }
      });
    } else if (value === 'invoice_number') {
      sorted = invoiceList.sort((invoiceA, invoiceB) => {
        if (sortAscending) {
          var invNumA = invoiceA.invoice_number.toUpperCase();
          var invNumB = invoiceB.invoice_number.toUpperCase();
          return invNumA < invNumB ? -1 : invNumA > invNumB ? 1 : 0;
        } else {
          var invNumA = invoiceA.invoice_number.toUpperCase();
          var invNumB = invoiceB.invoice_number.toUpperCase();
          return invNumA > invNumB ? -1 : invNumA < invNumB ? 1 : 0;
        }
      });
    } else if (value === 'date') {
      sorted = invoiceList.sort((invoiceA, invoiceB) => {
        if (sortAscending) {
          var dateA = new Date(invoiceA.date);
          var dateB = new Date(invoiceB.date);
          return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
        } else {
          var dateA = new Date(invoiceA.date);
          var dateB = new Date(invoiceB.date);
          return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        }
      });
    } else if (value === 'status') {
      sorted = invoiceList.sort((invoiceA, invoiceB) => {
        if (sortAscending) {
          var statusA = invoiceA.status.toUpperCase();
          var statusB = invoiceB.status.toUpperCase();
          return statusA < statusB ? -1 : statusA > statusB ? 1 : 0;
        } else {
          var statusA = invoiceA.status.toUpperCase();
          var statusB = invoiceB.status.toUpperCase();
          return statusA > statusB ? -1 : statusA < statusB ? 1 : 0;
        }
      });
    } else if (value === 'dueDate') {
      sorted = invoiceList.sort((invoiceA, invoiceB) => {
        if (sortAscending) {
          var dateA = new Date(invoiceA.due_date);
          var dateB = new Date(invoiceB.due_date);
          return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
        } else {
          var dateA = new Date(invoiceA.date);
          var dateB = new Date(invoiceB.date);
          return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        }
      });
    } else if (value === 'amount') {
      sorted = invoiceList.sort((invoiceA, invoiceB) => {
        if (sortAscending) {
          var amountA = invoiceA.amount;
          var amountB = invoiceB.amount;
          return amountA < amountB ? -1 : amountA > amountB ? 1 : 0;
        } else {
          var amountA = invoiceA.amount;
          var amountB = invoiceB.amount;
          return amountA > amountB ? -1 : amountA < amountB ? 1 : 0;
        }
      });
    }

    // place batched invoices at the front of the list, retain sort order
    var batches = [];
    if (invoiceList !== undefined) {
      sorted = sorted.filter((inv) => {
        if (inv.batched) {
          batches.push(inv);
        } else {
          return inv;
        }
      });
      sorted = [...batches, ...sorted];
    }

    setInvoiceList(sorted);

    if (invoiceList !== undefined) {
      let updatedInvoices = invoiceList.map((invoice) => {
        return (
          <tr key={invoice.id}>
            <td>
              {selectAll === true ? (
                <input
                  type='checkbox'
                  name={invoice.id}
                  value={invoice.selected}
                  id={invoice.id}
                  onChange={(e) => updateSelectedInvoices(e.target.name)}
                />
              ) : (
                <input
                  type='checkbox'
                  name={invoice.id}
                  value={invoice.selected}
                  id={invoice.id}
                  onChange={(e) => updateSelectedInvoices(e.target.name)}
                />
              )}
            </td>
            <td className='text-left'>
              {new Date(invoice.date).toLocaleDateString()}
            </td>
            <td className='text-left'>{invoice.invoice_number}</td>
            <td className='text-left'>
              <input type='checkbox' disabled value={!!invoice.batched} />
            </td>
            <td className='text-left'>{invoice.customer}</td>
            <td className='text-left'>{invoice.status}</td>
            <td className='text-left'>
              {new Date(invoice.due_date).toLocaleDateString()}
            </td>
            <td className='text-right'>${invoice.balance}</td>
            <td className='text-right'>
              $
              <input
                type='number'
                id={invoice.id}
                name={invoice.id + 'balance'}
                value={invoice.paymentAmount}
                onChange={(e) => updateBalance(invoice.id, e.target.value)}
              />
            </td>
          </tr>
        );
      });

      setInvoices(updatedInvoices);
    }
  };

  const updateSelectedInvoices = (id) => {
    // if selectAll is true, remove invoice from select state
    if (selectAll === true) {
      let i = selectedInvoices.indexOf(id);
      // console.log(invoiceList)
      let updatedInvoices = invoiceList.filter((invoice) => {
        return invoice.id !== id;
      });
      // if (i >= 0) {
      // invoiceList.splice(i, 1);
      let selection = selectedInvoices.splice(i, 1);
      setSelection(selection);
      setInvoiceList(updatedInvoices);
      // }
    } else {
      let selection = [];

      setInvoiceList(
        invoiceList.map((invoice) => {
          if (invoice.id === id) {
            invoice.selected = !invoice.selected;
          }
          if (invoice.selected === true) selection.push(invoice.id);
          return invoice;
        })
      );
      let updatedInvoices = invoiceList.map((invoice) => {
        return (
          <tr key={invoice.id}>
            <td>
              {selectAll === true ? (
                <input
                  type='checkbox'
                  name={invoice.id}
                  value={invoice.selected}
                  id={invoice.id}
                  onChange={(e) => updateSelectedInvoices(e.target.name)}
                />
              ) : (
                <input
                  type='checkbox'
                  name={invoice.id}
                  value={invoice.selected}
                  id={invoice.id}
                  onChange={(e) => updateSelectedInvoices(e.target.name)}
                />
              )}
            </td>
            <td className='text-left'>
              {new Date(invoice.date).toLocaleDateString()}
            </td>
            <td className='text-left'>{invoice.invoice_number}</td>
            <td className='text-left'>
              <input type='checkbox' disabled value={!!invoice.batched} />
            </td>
            <td className='text-left'>{invoice.customer}</td>
            <td className='text-left'>{invoice.status}</td>
            <td className='text-left'>
              {new Date(invoice.due_date).toLocaleDateString()}
            </td>
            <td className='text-right'>${invoice.balance}</td>
            <td className='text-right'>
              $
              <input
                type='number'
                id={invoice.id}
                name={invoice.id + 'balance'}
                value={invoice.paymentAmount}
                onChange={(e) => updateBalance(invoice.id, e.target.value)}
              />
            </td>
          </tr>
        );
      });

      setInvoices(updatedInvoices);
      setSelection(selection);
    }
  };

  useEffect(() => {
    // console.log("selected invoices:",selectedInvoices)
    props.handleSelection(selectedInvoices);
  }, [selectedInvoices]);

  const updateBalance = (id, value) => {
    // console.log(invoiceList)
    // console.log(id, value)
    // const updatedInvoice = invoices.find(invoice => invoice.id === id)
    let filteredInvoices = invoiceList.filter(
      (invoice) => invoice.status !== 'paid'
    );
    let updatedInvoices = filteredInvoices.map((invoice) => {
      if (invoice.id == id) {
        if (Number(value) >= Number(invoice.balance)) {
          invoice.paymentAmount = invoice.balance;
        } else {
          invoice.paymentAmount = value;
        }
      }
      return (
        <tr key={invoice.id}>
          <td>
            {selectAll === true ? (
              <input
                type='checkbox'
                name={invoice.id}
                value={invoice.selected}
                id={invoice.id}
                onChange={(e) => updateSelectedInvoices(e.target.name)}
              />
            ) : (
              <input
                type='checkbox'
                name={invoice.id}
                value={invoice.selected}
                id={invoice.id}
                onChange={(e) => updateSelectedInvoices(e.target.name)}
              />
            )}
          </td>
          <td className='text-left'>
            {new Date(invoice.date).toLocaleDateString()}
          </td>
          <td className='text-left'>{invoice.invoice_number}</td>
          <td className='text-left'>
            <input type='checkbox' disabled checked={!!invoice.batched} />
          </td>
          <td className='text-left'>{invoice.customer}</td>
          <td className='text-left'>{invoice.status}</td>
          <td className='text-left'>
            {new Date(invoice.due_date).toLocaleDateString()}
          </td>
          <td className='text-right'>${invoice.balance}</td>
          <td className='text-right'>
            $
            <input
              type='number'
              id={invoice.id}
              name={invoice.id + 'balance'}
              value={invoice.paymentAmount}
              onChange={(e) => updateBalance(invoice.id, e.target.value)}
            />
          </td>
        </tr>
      );
    });
    // console.log(updatedInvoices)
    setInvoices(updatedInvoices);
    // console.log(updatedInvoice)
    // invoice
  };

  useEffect(() => {
    // console.log(invoiceList)
    if (invoiceList !== undefined) {
      let filteredInvoices = invoiceList.filter(
        (invoice) => invoice.status !== 'paid'
      );
      setInvoices(
        filteredInvoices.map((invoice) => {
          return (
            <tr key={invoice.id}>
              <td>
                {selectAll === true ? (
                  <input
                    type='checkbox'
                    name={invoice.id}
                    value={invoice.selected}
                    id={invoice.id}
                    onChange={(e) => updateSelectedInvoices(e.target.name)}
                  />
                ) : (
                  <input
                    type='checkbox'
                    name={invoice.id}
                    value={invoice.selected}
                    id={invoice.id}
                    onChange={(e) => updateSelectedInvoices(e.target.name)}
                  />
                )}
              </td>
              <td className='text-left'>
                {new Date(invoice.date).toLocaleDateString()}
              </td>
              <td className='text-left'>{invoice.invoice_number}</td>
              <td className='text-left'>
                <input type='checkbox' disabled checked={!!invoice.batched} />
              </td>
              <td className='text-left'>{invoice.customer}</td>
              <td className='text-left'>{invoice.status}</td>
              <td className='text-left'>
                {new Date(invoice.due_date).toLocaleDateString()}
              </td>
              <td className='text-right'>${invoice.balance}</td>
              <td className='text-right'>
                $
                <input
                  type='number'
                  id={invoice.id}
                  name={invoice.id + 'balance'}
                  value={invoice.paymentAmount}
                  onChange={(e) => updateBalance(invoice.id, e.target.value)}
                />
              </td>
            </tr>
          );
        })
      );
    }
  }, [invoiceList]);

  return (
    <table className='listTable'>
      <thead>
        <tr>
          {/* <th><input type="checkbox" name="all" id="all" onChange={e => handleMassSelect(e)} /></th> */}
          <th></th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('date');
              }}
            >
              <div className='text-left'>DATE</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'date' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('invoice_number');
              }}
            >
              <div className='text-left'>INVOICE #</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'invoice_number' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th className='text-left'>IN BATCH</th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('customer');
              }}
            >
              <div className='text-left'>CUSTOMER NAME</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'customer' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('status');
              }}
            >
              <div className='text-left'>STATUS</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'status' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th>
            <div
              className='column-name-wrapper'
              onClick={() => {
                sortRows('dueDate');
              }}
            >
              <div className='text-left'>DUE DATE</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'dueDate' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th>
            <div
              className='column-name-wrapper justify-right'
              onClick={() => {
                sortRows('amount');
              }}
            >
              <div className='text-right'>BALANCE</div>
              <button
                className={
                  (sortAscending ? 'sort-arrow-up' : 'sort-arrow-down') +
                  (sortValue === 'balance' ? ' highlight-arrow' : '')
                }
                onClick={() => setSortAscending(!sortAscending)}
              >
                {' '}
              </button>
            </div>
          </th>

          <th className='text-right'>PAYMENT AMOUNT</th>
        </tr>
      </thead>
      <tbody>{invoices}</tbody>
    </table>
  );
}
