import config from '../config';
import TokenService from './TokenService';

const InvoiceService = {
  // get invoices from DB per client
  getInvoices(org) {
    return fetch(`${config.API_ENDPOINT}/invoices`, {
      method: 'GET',
      // body: JSON.stringify(invoices),
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization: org,
      },
    }).then(async (res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }

      const invoiceList = await res.json();
      const invoices = invoiceList.invoices.map((invoice) => {
        let paymentAmount = invoice.paymentAmount;
        if (paymentAmount === null) {
          paymentAmount = invoice.balance;
        }

        return { ...invoice, paymentAmount: paymentAmount };
      });

      return invoices;
    });
  },
  // get invoice by id from db per client
  getInvoiceById(invoice, org) {
    console.log(
      'getinvoicebyid: ',
      invoice,
      `${config.API_ENDPOINT}/invoices/${invoice}`
    );
    return fetch(`${config.API_ENDPOINT}/invoices/${invoice}`, {
      method: 'GET',
      // body: JSON.stringify(invoices),
      headers: {
        // Authorization: `Bearer ${TokenService.getAuthToken()}`,
        Organization: org,
        'content-type': 'application/json',
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  // resync invoices in client db to match invoices in books and fetch all from db (per client)
  resyncInvoices(orgId) {
    console.log(orgId);
    return fetch(`${config.API_ENDPOINT}/invoices/resync`, {
      method: 'GET',
      // body: JSON.stringify(invoices),
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        Organization: orgId,
        'content-type': 'application/json',
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  updateInvoiceBatch(invId, paymentAmount, org) {
    console.log(invId);
    return fetch(`${config.API_ENDPOINT}/invoices/update `, {
      method: 'PATCH',
      body: JSON.stringify({ invoice: invId, paymentAmount }),
      headers: {
        // Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization: org,
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },

  debatchInvoice(invId, org) {
    console.log(invId);
    return fetch(`${config.API_ENDPOINT}/invoices/debatch `, {
      method: 'PATCH',
      body: JSON.stringify({ invoice: invId }),
      headers: {
        // Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        'content-type': 'application/json',
        Organization: org,
      },
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
  markInvoicesPaid(invoices, org) {
    return fetch(
      `${config.API_ENDPOINT}/org/${config.TEST_BOOKS_ORG_ID}/invoices/pay/${invoices}`,
      {
        method: 'PATCH',
        body: JSON.stringify(invoices),
        headers: {
          // Authorization: `Bearer ${TokenService.getAuthToken()}`,
          'content-type': 'application/json',
          Organization: org,
        },
      }
    ).then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          throw error;
        });
      }
      return res.json();
    });
  },
};

export default InvoiceService;
