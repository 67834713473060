import React, { Component } from 'react'
import numberToWords from 'number-to-words'
import './ComponentToPrint.css'

export class ComponentToPrint2 extends Component {
  render() {
    // const { date, bill, reference, vendor, balance, amount } = this.props.bill
    // const { company_name, street, city, state, zip } = this.props.company
    // const { checkLine1, checkLine2, checkLine3, checkLine4 } = this.props.positionSettings
    // const billRow = this.props.bills.length > 10 ? this.props.bills.map(bill => {
    //   return (
    //     <tr>
    //       <td>{bill.date}</td>
    //       <td>{bill.bill}</td>
    //       <td class="text-right">${bill.amount}</td>
    //       <td class="text-right">${bill.balance}</td>
    //     </tr>
    //   )
    // }) : 'See Extended Stub Report'
    // console.log(checkLine1)
    console.log("Component 2")
    return (
      <section class="PrintPage Group_1">
        <div className="checkStub1">
          <div class="checkStubPreHeader">
            {/* <p>{vendor}</p> */}
            {/* <p>{date}</p> */}
          </div>
          <table className="stubTable">
            <thead>
              <tr className="checkStubHeader">
                <th>Bill Date</th>
                <th>Bill Number</th>
                <th className="text-right">Amount</th>
                <th className="text-right">Paid</th>
              </tr>
            </thead>
            <tbody>
              {/* {billRow} */}
            </tbody>
          </table>
        </div>
      </section>
    )
  }
}

export default ComponentToPrint2;