import React, { useState, useEffect,useContext } from 'react'
import ComponentToPrint from '../../components/Modal/ComponentToPrint'
import TemplateService from '../../services/TemplateService'
import Store from '../../dummy/store.json'
import Context from '../../contexts/context'
import BillService from '../../services/BillService'

export default function EditView() {
  const [sampleBill, setSampleBill] = useState({})
  const [company, setCompany] = useState()
  const [positionSettings, setpositionSettings] = useState(Store.positionSettings)
  const userContext = useContext(Context)
  const currentUser = {}

  useEffect(() => {
    // grab client company based on user from DB
    // for testing
    BillService.getBillById(1).then(bill =>{
      setSampleBill(bill.bill)
    })
    TemplateService.getTemplateSettings().then(settings =>{
      console.log("settings: ",settings)
      setpositionSettings(settings)
    })
    currentUser = userContext.currentUser
    console.log(currentUser)
    setCompany(currentUser.company)
  }, [])

  const handleChange = (v,line,nm) => {
    const lineObj = positionSettings[line]
    setpositionSettings({ ...positionSettings, [line]: {...lineObj,[nm]:Number(v) }})
  }

  const handleSubmit = (e) =>{
    console.log(positionSettings)
    // updateDB with positionSettings
  }

  useEffect(()=>{
    console.log(positionSettings)
  },[positionSettings])

  // const { checkLine1, checkLine2, checkLine3, checkLine4 } = Store.positionSettings
  return (
    <div className="templateSettingsCard">
      <form className="templateSettingsForm">
        <h4>Update Check Positioning</h4>
        <div className="input-group">
          <label className="editCheckLabel" htmlFor="line1Y">Line 1 Position from top</label>
          <input type="number" value={positionSettings.checkLine1.top} name="line1Y" id="line1Y" onChange={e => handleChange(e.target.value,"checkLine1","top")} />
          <label className="editCheckLabel" htmlFor="line1X">Line 1 Position from Right</label>
          <input type="number" value={positionSettings.checkLine1.right} name="line1X" id="line1X" onChange={e => handleChange(e.target.value,"checkLine1","right")} />
        </div>
        <div className="input-group">
          <label className="editCheckLabel" htmlFor="line2Y">Line 2 Position from top</label>
          <input type="number" value={positionSettings.checkLine2.top} name="line2Y" id="line2Y" onChange={e => handleChange(e.target.value,"checkLine2","top")} />
          <label className="editCheckLabel" htmlFor="line1X">Line 2 Position from Left</label>
          <input type="number" value={positionSettings.checkLine2.left} name="line2X" id="line2X" onChange={e => handleChange(e.target.value,"checkLine2","left")} />
        </div>
        <div className="input-group">
          <label className="editCheckLabel" htmlFor="line3Y">Line 3 Position from top</label>
          <input type="number" value={positionSettings.checkLine3.top} name="line3Y" id="line3Y" onChange={e => handleChange(e.target.value,"checkLine3","top")} />
          <label className="editCheckLabel" htmlFor="line1X">Line 3 Position from Left</label>
          <input type="number" value={positionSettings.checkLine3.left} name="line3X" id="line3X" onChange={e => handleChange(e.target.value,"checkLine3","left")} />
        </div>
        <div className="input-group">
          <label className="editCheckLabel" htmlFor="line4Y">Line 4 Position from top</label>
          <input type="number" value={positionSettings.checkLine4.top} name="line4Y" id="line4Y" onChange={e => handleChange(e.target.value,"checkLine4","top")} />
          <label className="editCheckLabel" htmlFor="line1X">Line 4 Position from Left</label>
          <input type="number" value={positionSettings.checkLine4.left} name="line4X" id="line4X" onChange={e => handleChange(e.target.value,"checkLine4","left")} />
        </div>
        <input type="submit" value="update" className="btn add-button" onSubmit={e => handleSubmit(e)} />
      </form>
      {/* need to grab state from form and pass settings to component, should component be a child of the form? */}
      <ComponentToPrint company={company} bills={Store.bills} bill={sampleBill} positionSettings={positionSettings} />
    </div>
  )
}
