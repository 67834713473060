import config from '../config';
import TokenService from './TokenService';

const BillNachasService = {
  getBillNachas(orgID) {
    return fetch(`${config.API_ENDPOINT}/billsnacha`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        Organization: orgID,
        'content-type': 'application/json',
      },
    })
    .then((resp) =>
      resp.status !== 503 ? resp.json() : console.log('503: ', resp)
    )
    .then((res) => {
      return res.billsnacha;
    })
    .catch(() => alert('Something went wrong.'));
  },
  getBillHistory(nacha, user, orgID) {
    console.log("GETTING BILL HISTORY");
    console.log(nacha);
    const id = nacha.id;
    const batchDetails = nacha.details.map((bill) => {
      return {
        amount: bill.amount,
        idNumber: bill.idNumber,
        individualName: bill.individualName,
        id: id,
        // booksID: bill.booksID,
      };
    });
    const nachaObj = {
      // current user ID/organization id?
      user: user,
      vendorId: nacha.vendor.contact_id ?? '0',
      batch: {
        originatingDFI: user.routing_number ?? '0', 
      },
      details: batchDetails,
    };

    console.log(nachaObj);

    return fetch(`${config.API_ENDPOINT}/ach/billhistory`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Zoho-oauthtoken ${TokenService.getAuthToken()}`,
        Organization: orgID,
        'content-type': 'application/json',
      },
      body: JSON.stringify(nachaObj),
    })
    .then((resp) =>
      resp.status !== 503 ? resp.json() : console.log('503: ', resp)
    )
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch(() => alert('Something went wrong.'));
  }
};

export default BillNachasService;
