import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import '@react-page/editor/lib/index.css';
import { Provider } from './contexts/context'
// printer settings context?
import {
  fas,
  faSignOutAlt,
  faHome,
  faUsers,
  faPlusSquare,
  faBars,
  faUserTimes,
  faTimes,
  faTrashAlt,
  faCog,
  faSync
} from '@fortawesome/free-solid-svg-icons';

library.add(
  fab,
  fas,
  fas,
  faSignOutAlt,
  faHome,
  faUsers,
  faPlusSquare,
  faBars,
  faUserTimes,
  faTimes,
  faTrashAlt,
  faCog,
  faSync
);

ReactDOM.render(
  <React.StrictMode>
    <Provider>
    <Router>
      <App />
    </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
